/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { SpecificationDTO } from './SpecificationDTO';

export type ConstructionDTO = {
  id: number;
  description?: string;
  name?: string;
  delivery: boolean;
  installation: boolean;
  cableConnection: ConstructionDTO.cableConnection;
  fsm?: boolean;
  category?: string;
  stripId?: number;
  specifications: Array<SpecificationDTO>;
  unit?: string;
  localOverrideOperation?: ConstructionDTO.localOverrideOperation;
  airSided: boolean;
  orderBy?: number;
  useLabels: boolean;
  isActive?: boolean;
  hasValve?: boolean;
};

export namespace ConstructionDTO {

  export enum cableConnection {
    NONE = 'NONE',
    TWOSIDE = 'TWOSIDE',
  }

  export enum localOverrideOperation {
    NEVER = 'NEVER',
    NONE = 'NONE',
    TMU = 'TMU',
    BMD = 'BMD',
    COUPLING_RELAY = 'COUPLING_RELAY',
  }


}

