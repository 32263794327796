/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AutomaticDDCCalculationDTO } from '../models/AutomaticDDCCalculationDTO';
import type { CalculateAutomaticLayoutDataRequest } from '../models/CalculateAutomaticLayoutDataRequest';
import type { DdcDatapointsDTO } from '../models/DdcDatapointsDTO';
import type { DdcDatapointsDtoWithHierarchyId } from '../models/DdcDatapointsDtoWithHierarchyId';
import type { DdcDatapointsRealDataRequest } from '../models/DdcDatapointsRealDataRequest';
import type { DdcDtoQuantity } from '../models/DdcDtoQuantity';
import type { ItemQuantityDescriptionPriceType } from '../models/ItemQuantityDescriptionPriceType';
import type { RequiredLvbDTO } from '../models/RequiredLvbDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DdcService {

  /**
   * Berechnet die Aufteilung von vorgegebenen Datenpunkten mit vorgegebenen DDCs
   * Gibt die verfügbaren, die erforderlichen und eine Reserve der Datenpunkte zurück, indem die vorgegebenen Datenpunkte mit den Datenpunkten der vorgegebenen DDCs in diesem ASP verrechnet werden
   * @returns DdcDatapointsDTO OK
   * @throws ApiError
   */
  public static calculateDdcDatapointsUsingProvidedData({
    requestBody,
    body,
  }: {
    requestBody: DdcDatapointsRealDataRequest,
    /**
     * Eine Liste von Datenpunkten sowie eine Liste von DDC-Modulen
     */
    body?: any,
  }): CancelablePromise<DdcDatapointsDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/ddc/calculate-ddc-datapoints-using-provided-data',
      query: {
        'body': body,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Automatische DDC-Modulauslegung für Projekt
   * Startet die Automatische DDC-Modulauslegung für einem bestimmten ASP durch und gibt die passende Artikelkombination zurück
   * @returns AutomaticDDCCalculationDTO OK
   * @throws ApiError
   */
  public static calculateAutomaticDdcLayout({
    hierarchyId,
    requestBody,
    reserve = 1,
    relevantItemIds,
  }: {
    /**
     * Die ID des ASP's für den die Modulauslegung durchgeführt werden soll
     */
    hierarchyId: number,
    requestBody: Array<string>,
    /**
     * Die Reserve in Prozent (absolute Zahl). Beispiel: 1 = 100%: Es gibt keine Reserve, 2 = 200% = Doppelte Reserve für die vorhandenen Datenpunkte. Bereich: 1-2
     */
    reserve?: number,
    /**
     * Eine Liste von Artikelnamen, die in der Modulauslegung genutzt werden sollen
     */
    relevantItemIds?: any,
  }): CancelablePromise<AutomaticDDCCalculationDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/ddc/automatic-layout',
      query: {
        'hierarchyId': hierarchyId,
        'reserve': reserve,
        'relevantItemIds': relevantItemIds,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Automatische DDC-Modulauslegung für eine feste Anzahl von Datenpunkten
   * Startet die Automatische DDC-Modulauslegung für eine mitgegebene Anzahl von Datenpunkten durch und gibt die passende Artikelkombination zurück
   * @returns AutomaticDDCCalculationDTO OK
   * @throws ApiError
   */
  public static calculateAutomaticDdcLayoutUsingData({
    requestBody,
    body,
  }: {
    requestBody: CalculateAutomaticLayoutDataRequest,
    /**
     * Ein Objekt bestehend aus Regelkreisen, Datenpunkten, einer Reserve zwischen 1.0-2.0 und eine Liste von Artikelnamen, die in der Modulauslegung genutzt werden sollen.
     */
    body?: any,
  }): CancelablePromise<AutomaticDDCCalculationDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/ddc/automatic-layout-using-data',
      query: {
        'body': body,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Rufe die Eigenschaften der DDCs unter Nutzung des Artikelstamms ab
   * Ruft Eigenschaften der DDCs (Datenpunkte, LVB, Sortierreihenfolge, etc.) in Kombination des Artikelstamms und der lokalen Datenbank ab
   * @returns DdcDtoQuantity OK
   * @throws ApiError
   */
  public static getMeta({
    hierarchyId,
  }: {
    /**
     * (Optional) Wenn gegeben, werden die Anzahl der DDCs innerhalb dieses Datenpunktes (ASP!) zurückgegeben
     */
    hierarchyId?: number,
  }): CancelablePromise<Array<DdcDtoQuantity>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/ddc/meta',
      query: {
        'hierarchyId': hierarchyId,
      },
    });
  }

  /**
   * Überprüft anhand eines Projektes, ob die LVB-Auslegung bereits valide ist
   * Gibt die Anzahl und erforderliche Anzahl aller LVB Artikel für ein Projekt anhand der Artikelliste der Projekthierarchieelemente zurück
   * @returns RequiredLvbDTO OK
   * @throws ApiError
   */
  public static getLvbMapping({
    hierarchyId,
  }: {
    /**
     * Die ID des Projekthierarchieelementes, wofür die LVB Auslegung geprüft werden soll.
     */
    hierarchyId: number,
  }): CancelablePromise<Array<RequiredLvbDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/ddc/lvb',
      query: {
        'hierarchyId': hierarchyId,
      },
    });
  }

  /**
   * @deprecated
   * @returns ItemQuantityDescriptionPriceType OK
   * @throws ApiError
   */
  public static getDdcItemsConnectedToProperty({
    hierarchyId,
  }: {
    hierarchyId: number,
  }): CancelablePromise<Array<ItemQuantityDescriptionPriceType>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/ddc/items',
      query: {
        'hierarchyId': hierarchyId,
      },
    });
  }

  /**
   * Gibt eine Auflistung der Datenpunkte eines ASP's zurück
   * Gibt die verfügbaren, die erforderlichen und eine Reserve der Datenpunkte zurück, indem die Datenpunkte der Funktionslisten zusammengezählt werden und mit den Datenpunkten der verknüpften DDCs in diesem ASP verrechnet werden
   * @returns DdcDatapointsDTO OK
   * @throws ApiError
   */
  public static calculateDdcDatapoints({
    hierarchyId,
  }: {
    /**
     * Die ID des ASP's für den die Berechnung durchgeführt werden soll. Muss vom Typ ASP sein, wirft sonst einen Fehler.
     */
    hierarchyId: number,
  }): CancelablePromise<DdcDatapointsDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/ddc/calculate-ddc-datapoints',
      query: {
        'hierarchyId': hierarchyId,
      },
    });
  }

  /**
   * Gibt eine Liste mit allen ASPs eines Projektes zurück und verrechnet die Datenpunkte
   * Sammelt alle ASPs eines Projektes, berechnet die verfügbaren, erforderlichen und Reserve der Datenpunkte für alle ASPs einzeln. Die Datenpunkte der Funktionslisten des jeweiligen ASPs werden zusammengezählt und mit den Datenpunkten der verknüpften DDCs in diesem ASP verrechnet.
   * @returns DdcDatapointsDtoWithHierarchyId OK
   * @throws ApiError
   */
  public static calculateDdcDatapointsUsingProjectId({
    projectId,
  }: {
    /**
     * Die ID des Projektes, in dem die Berechnung erfolgen soll
     */
    projectId: number,
  }): CancelablePromise<Array<DdcDatapointsDtoWithHierarchyId>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/ddc/calculate-ddc-datapoints-using-project-id',
      query: {
        'projectId': projectId,
      },
    });
  }

}
