/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FunctionList } from '../models/FunctionList';
import type { GeneratedIdDto } from '../models/GeneratedIdDto';
import type { NamedFunctionList } from '../models/NamedFunctionList';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FunctionListService {

  /**
   * Alle Funktionslisten aus der Makrobibliothek abrufen
   * Von Admins genutzt: Ruft alle Funktionslisten aus der Makrobibliothek ab. Potentielle Verbesserung: Pagination einführen!
   * @returns FunctionList OK
   * @throws ApiError
   */
  public static getFunctionList(): CancelablePromise<Array<FunctionList>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/macrolib/functionlist',
    });
  }

  /**
   * Neue Funktionsliste anlegen
   * Von Admins genutzt: Legt eine neue Funktionsliste in der Makrobibliothek an
   * @returns FunctionList OK
   * @throws ApiError
   */
  public static addFunctionList({
    requestBody,
    functionList,
  }: {
    requestBody: FunctionList,
    /**
     * Enthält Parameter der anzulegenden Funktionsliste. Parameter functionListId ist dabei benötigt und darf nicht null sein.
     */
    functionList?: any,
  }): CancelablePromise<FunctionList> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/macrolib/functionlist',
      query: {
        'functionList': functionList,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Spezifische Funktionsliste mittels ID abrufen
   * Von Admins genutzt: Ruft eine bestimmte Funktionsliste mithilfe einer ID ab
   * @returns FunctionList OK
   * @throws ApiError
   */
  public static getFunctionListById({
    id,
  }: {
    /**
     * Die ID der Funktionsliste
     */
    id: number,
  }): CancelablePromise<FunctionList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/macrolib/functionlist/{id}',
      path: {
        'id': id,
      },
    });
  }

  /**
   * Löscht eine Funktionsliste in der Makrobibliothek
   * Löscht eine Funktionsliste. Sollte sie noch irgendwo verknüpft sein, wird ein Error geworfen
   * @returns any OK
   * @throws ApiError
   */
  public static deleteFunctionListById({
    id,
  }: {
    id: number,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/macrolib/functionlist/{id}',
      path: {
        'id': id,
      },
    });
  }

  /**
   * Funktionsliste in der Makrobibliothek partiell ändern
   * Von Admins genutzt: Ändert Attribute einer bestimmten Funktionsliste. Hier sollte später eine bessere Typisierung eingeführt werden.
   * @returns FunctionList OK
   * @throws ApiError
   */
  public static updateFunctionList({
    id,
    requestBody,
    request,
  }: {
    /**
     * ID der Funktionsliste, die aktualisiert werden soll
     */
    id: number,
    requestBody: Record<string, any>,
    /**
     * Werte, die aktualisiert werden sollen. Falls ein Wertepaar nicht im Objekttypen FunctionList vorhanden ist, wird eine Exception geworfen
     */
    request?: any,
  }): CancelablePromise<FunctionList> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/macrolib/functionlist/{id}',
      path: {
        'id': id,
      },
      query: {
        'request': request,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Funktionsliste für eine instanziierte Anlage innerhalb eines Projektes abrufen
   * Von SALT-Nutzern genutzt: Ruft alle Funktionslisten für eine Anlage, die in einem Projekt instanziiert ist, ab
   * @returns NamedFunctionList OK
   * @throws ApiError
   */
  public static getFunctionListByProjectPlantId({
    projectPlantId,
  }: {
    /**
     * Die ID der Anlage innerhalb eines Projektes
     */
    projectPlantId: number,
  }): CancelablePromise<Array<NamedFunctionList>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/macrolib/functionlist/project-plant',
      query: {
        'projectPlantId': projectPlantId,
      },
    });
  }

  /**
   * Funktionsliste für eine Anlage aus der Makrobibliothek abrufen
   * Von Admins genutzt: Ruft alle Funktionslisten für eine Anlage, die in der Makrobibliothek gespeichert ist, ab
   * @returns FunctionList OK
   * @throws ApiError
   */
  public static getFunctionListByPlantId({
    plantId,
  }: {
    /**
     * Die ID der Anlage der Makrobibliothek
     */
    plantId: number,
  }): CancelablePromise<Array<FunctionList>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/macrolib/functionlist/plant',
      query: {
        'plantId': plantId,
      },
    });
  }

  /**
   * Nächstmögliche Funktionsliste für manuelle Funktionslisten für die Makrobibliothek generieren
   * Von Admins genutzt: Der Wertebereich benutzerdefinierter Funktionsliste für SALT ist zwischen 1000-1999. Hier wird die nächstmögliche, nicht verwendete Funktionslisten-ID generiert. Sollten alle Werte gefüllt sein, wird hier eineException geworfen. Falls das der Fall ist, muss sich hier ein neues Konzept ausgedacht werden sollen.
   * @returns GeneratedIdDto OK
   * @throws ApiError
   */
  public static getNextSuitableId(): CancelablePromise<GeneratedIdDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/macrolib/functionlist/next-suitable-id',
    });
  }

  /**
   * Funktionslisten für ein Hierarchie-Element abrufen
   * Für SALT-Nutzer: Ruft alle Funktionsliste, die sich innerhalb eines Projekt-Hierarchie-Elementes befindet (inkludiert Kinder-Knoten)
   * @returns NamedFunctionList OK
   * @throws ApiError
   */
  public static getFunctionListByHierarchyId({
    hierarchyId,
  }: {
    /**
     * Die ID des Hierarchieelementes, das in einem Projekt liegt, für die die Funktionslisten abgerufen werden sollen
     */
    hierarchyId: number,
  }): CancelablePromise<Array<NamedFunctionList>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/macrolib/functionlist/hierarchy',
      query: {
        'hierarchyId': hierarchyId,
      },
    });
  }

}
