/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SettingPostRequest = {
  name: string;
  value: string;
  type: SettingPostRequest.type;
};

export namespace SettingPostRequest {

  export enum type {
    INT = 'INT',
    FLOAT = 'FLOAT',
    STRING = 'STRING',
    LONG = 'LONG',
  }


}

