import {
  PublicPlantService,
  ConstructionsService,
  StripService,
  FileService,
  PlantGroupService
} from '@/__generated__'
const getAllPublicPlants = () => PublicPlantService.getAllPublicPlants()
const getConstructions = () => ConstructionsService.getVisibleConstructions()
const getStripsIncludeConstructions = () => StripService.getStripsIncludeConstructions()
const getPlantSections = () => PlantGroupService.getPlantSections()
const getPublicImages = () => FileService.getPublicImages()
export { getAllPublicPlants, getConstructions, getStripsIncludeConstructions, getPublicImages, getPlantSections }
