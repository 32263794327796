import { render, staticRenderFns } from "./ProjectStateChipComponent.vue?vue&type=template&id=801004cc&scoped=true&"
import script from "./ProjectStateChipComponent.vue?vue&type=script&setup=true&lang=ts&"
export * from "./ProjectStateChipComponent.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./ProjectStateChipComponent.vue?vue&type=style&index=0&id=801004cc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "801004cc",
  null
  
)

export default component.exports