import { ItemQuantity, ManualServiceShoppingService, ServiceExpensesCalculatorService } from '@/__generated__'
const getCurrentServices = (hierarchyId: number, projectId: number) => ServiceExpensesCalculatorService.getCurrentServices({ hierarchyId, projectId })
const getDatapointsForProject = (hierarchyId: number, projectId: number) => ServiceExpensesCalculatorService.getDatapointsForProject({ hierarchyId, projectId })
const persistCalculation = (hierarchyId: number, projectId: number, method: 'DATAPOINTS' | 'REFERENCE_TIME') => ServiceExpensesCalculatorService.persistCalculation({ hierarchyId, method, projectId })
const getRoleTimes = (hierarchyId: number, projectId: number) => ServiceExpensesCalculatorService.getRoleTimes({ hierarchyId, projectId })
const getEstimatedDatapointPrice = (hierarchyId: number, projectId: number) => ServiceExpensesCalculatorService.getEstimatedDatapointPrice({ hierarchyId, projectId })
const deleteServiceEntry = (hierarchyId: number, projectId: number, itemName: string) => ManualServiceShoppingService.deleteServiceEntry({ hierarchyId, itemName, projectId })
const addServiceEntry = (hierarchyId: number, itemQuantity: ItemQuantity, projectId: number) => ManualServiceShoppingService.addServiceEntry({ hierarchyId, requestBody: itemQuantity, projectId })
const modifyServiceEntry = (hierarchyId: number, itemQuantity: ItemQuantity, projectId: number) => ManualServiceShoppingService.modifyServiceEntry({ hierarchyId, requestBody: itemQuantity, projectId })
const changeCustomStatus = (customized: boolean, hierarchyId: number, itemId: string, projectId: number) => ManualServiceShoppingService.changeCustomStatus({ customized, hierarchyId, itemId, projectId })
const changeRoleValue = (hierarchyId: number, itemId: string, projectId: number, roleId: string, value: number) => ManualServiceShoppingService.changeRoleValue({ hierarchyId, itemId, projectId, roleId, requestBody: value })
const deleteRoleValue = (hierarchyId: number, itemId: string, projectId: number, roleId: string) => ManualServiceShoppingService.deleteRoleValue({ hierarchyId, itemId, projectId, roleId })
export { getCurrentServices, getDatapointsForProject, persistCalculation, deleteServiceEntry, addServiceEntry, modifyServiceEntry, changeCustomStatus, changeRoleValue, deleteRoleValue, getRoleTimes, getEstimatedDatapointPrice }
