declare global {
  interface Window {
    ENV_BACKEND_URI: string;
    ENV_APPLICATION_ID: string;
    ENV_TENANT_ID: string;
    ENV_ITEM_IMAGES_FOLDER: string;
    ENV_HELP_URL: string
  }
}

export const BACKEND_URI = window.ENV_BACKEND_URI
export const APPLICATION_ID = window.ENV_APPLICATION_ID
export const TENANT_ID = window.ENV_TENANT_ID
export const ITEM_IMAGES_FOLDER = window.ENV_ITEM_IMAGES_FOLDER
export const HELP_URL = window.ENV_HELP_URL
