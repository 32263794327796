/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BlobResourceDTO } from '../models/BlobResourceDTO';
import type { BlobUrl } from '../models/BlobUrl';
import type { SASPermissionToken } from '../models/SASPermissionToken';
import type { SimpleUrl } from '../models/SimpleUrl';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FileService {

  /**
   * Generiere ein SAS-Permission Token für die Dateien im jeweiligen Container
   * Generiert ein feingranulares Token, das SALT-Nutzer nutzen können, um Dateien im Blob abzurufen (Anlagenbilder, Tooltips, etc.). Im Moment werden nur Lesezugriffe gewährt
   * @returns SASPermissionToken OK
   * @throws ApiError
   */
  public static getToken(): CancelablePromise<SASPermissionToken> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/blob/sas',
    });
  }

  /**
   * URL für alle abrufbaren Anlagenbilder generieren
   * Generiert die URL für alle verfügbaren Anlagenbilder, inkludiert dabei die Makro Id, die für die Makrobibliothek verwendet werden.
   * @returns BlobUrl OK
   * @throws ApiError
   */
  public static getPublicImages(): CancelablePromise<Array<BlobUrl>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/blob/plants/public-images',
    });
  }

  /**
   * URL für alle Anlagenbilder generieren
   * Generiert die URL für alle verfügbaren Anlagenbilder, die für die Makrobibliothek verwendet werden.
   * @returns BlobResourceDTO OK
   * @throws ApiError
   */
  public static getAllImages(): CancelablePromise<Array<BlobResourceDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/blob/plants/images',
    });
  }

  /**
   * URL für spezifisches Anlagenbild generieren
   * Generiert die URL für das erste Bild einer bestimmten Anlage, was für die Makrobibliothek verwendet wird. Identifizierung mittels Makro-ID
   * @returns BlobResourceDTO OK
   * @throws ApiError
   */
  public static getImage({
    macroId,
  }: {
    macroId: string,
  }): CancelablePromise<BlobResourceDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/blob/plants/images/{macroId}',
      path: {
        'macroId': macroId,
      },
    });
  }

  /**
   * URL für alle Bilder einer spezifischen Anlage generieren
   * Generiert die URL für alle Bilder einer bestimmten Anlage, was für die Makrobibliothek verwendet wird. Identifizierung mittels Makro-ID
   * @returns BlobResourceDTO OK
   * @throws ApiError
   */
  public static getImages({
    macroId,
  }: {
    macroId: string,
  }): CancelablePromise<Array<BlobResourceDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/blob/plants/images/{macroId}/all',
      path: {
        'macroId': macroId,
      },
    });
  }

  /**
   * URL für die Field-Level-Help generieren
   * Generiert die URL für die Field-Level-Help. Die Field-Level-Help bietet dabei Tooltips für das Frontend. XML-Datei, die von der Dok-Abteilung gepflegt wird.
   * @returns SimpleUrl OK
   * @throws ApiError
   */
  public static getFieldLevelHelpUrl(): CancelablePromise<SimpleUrl> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/blob/field-level-help-url',
    });
  }

}
