/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PageVendorDTO } from '../models/PageVendorDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NavVendorService {

  /**
   * Händler im Artikelstamm suchen
   * Sucht Händler in Navision basierend auf einer Suchabfrage und der Seitennummer.
   * @returns PageVendorDTO OK
   * @throws ApiError
   */
  public static getVendors({
    page,
    query,
  }: {
    /**
     * Die Seite der Ergebnisse, beginnend bei 0.
     */
    page?: number,
    /**
     * Optional. Die Abfragezeichenkette für die Suche nach Händlern.
     */
    query?: string,
  }): CancelablePromise<PageVendorDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/nav/vendors',
      query: {
        'page': page,
        'query': query,
      },
    });
  }

}
