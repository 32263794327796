/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PriceSumDTO } from '../models/PriceSumDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PriceService {

  /**
   * Preis für einen bestimmten Artikelnamen abrufen
   * Ruft den Preis für einen spezifizierten Artikelnamen ab.
   * @returns number OK
   * @throws ApiError
   */
  public static getPrice({
    name,
  }: {
    /**
     * Der Name des Artikels.
     */
    name: string,
  }): CancelablePromise<number> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/prices',
      query: {
        'name': name,
      },
    });
  }

  /**
   * Aktuellen Preis für das Projekt abrufen
   * Ruft den aktuellen Preis für ein bestimmtes Projekt ab.
   * @returns PriceSumDTO OK
   * @throws ApiError
   */
  public static getCurrentPriceForProject({
    projectId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
  }): CancelablePromise<PriceSumDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/prices/{projectId}',
      path: {
        'projectId': projectId,
      },
    });
  }

  /**
   * Aktuellen Servicepreis für Hierarchie-ID abrufen
   * Ruft den aktuellen Servicepreis (Preis der Dienstleistungen) für eine spezifische Hierarchie-ID innerhalb eines Projekts ab.
   * @returns PriceSumDTO OK
   * @throws ApiError
   */
  public static getCurrentServicePriceForHierarchyId({
    projectId,
    hierarchyId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die Hierarchie-ID.
     */
    hierarchyId: number,
  }): CancelablePromise<PriceSumDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/prices/{projectId}/service',
      path: {
        'projectId': projectId,
      },
      query: {
        'hierarchyId': hierarchyId,
      },
    });
  }

  /**
   * Preis für Hierarchie-ID ohne Service abrufen
   * Ruft den aktuellen Preis für eine spezifische Hierarchie-ID innerhalb eines Projekts ab, wobei Servicekosten ignoriert werden.
   * @returns PriceSumDTO OK
   * @throws ApiError
   */
  public static getCurrentPriceForHierarchyIdIgnoreService({
    projectId,
    hierarchyId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die Hierarchie-ID.
     */
    hierarchyId: number,
  }): CancelablePromise<PriceSumDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/prices/{projectId}/hierarchy',
      path: {
        'projectId': projectId,
      },
      query: {
        'hierarchyId': hierarchyId,
      },
    });
  }

  /**
   * Preise für Projekt gruppieren
   * Ruft den aktuellen Preis für ein Projekt ab und gruppiert die Preise nach Kategorien.
   * @returns PriceSumDTO OK
   * @throws ApiError
   */
  public static getCurrentPriceForProjectAndGroup({
    projectId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
  }): CancelablePromise<Record<string, PriceSumDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/prices/{projectId}/group',
      path: {
        'projectId': projectId,
      },
    });
  }

}
