/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ItemQuantityWithSourcePriceAndType } from '../models/ItemQuantityWithSourcePriceAndType';
import type { ProjectHierarchyDTO } from '../models/ProjectHierarchyDTO';
import type { ProjectHierarchyElementDTO } from '../models/ProjectHierarchyElementDTO';
import type { ProjectHierarchyItemsRequest } from '../models/ProjectHierarchyItemsRequest';
import type { ProjectHierarchyRequest } from '../models/ProjectHierarchyRequest';
import type { ProjectHierarchySingleItemRequest } from '../models/ProjectHierarchySingleItemRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProjectHierarchyService {

  /**
   * Artikel eines Projekthierarchieelements abrufen
   * Holt die Artikel, die mit einem spezifischen Hierarchieelement eines Projekts verbunden sind.
   * @returns ItemQuantityWithSourcePriceAndType OK
   * @throws ApiError
   */
  public static getItemsConnectedToProjectHierarchy({
    projectId,
    hierarchyId,
    source,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die ID des Hierarchieelements.
     */
    hierarchyId: number,
    /**
     * Optional. Die Quelle der Hierarchie-Artikel-Relation.
     */
    source?: 'COUPLING_RELAY' | 'POWER_ADAPTER' | 'FEED' | 'OVERVOLTAGE' | 'FSM' | 'KA' | 'NETWORK' | 'MISCELLANEOUS' | 'CASINGS_MISC' | 'CASINGS' | 'INSTALLATION_POSITIONS' | 'DDC' | 'OTHER' | 'SERVICE',
  }): CancelablePromise<Array<ItemQuantityWithSourcePriceAndType>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/projects/{projectId}/hierarchy/{hierarchyId}/items',
      path: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
      },
      query: {
        'source': source,
      },
    });
  }

  /**
   * Artikel eines Projekthierarchieelements überschreiben
   * Überschreibt die Artikel, die mit einem spezifischen Hierarchieelement eines Projekts verbunden sind.
   * @returns ItemQuantityWithSourcePriceAndType OK
   * @throws ApiError
   */
  public static overwriteItemsConnectedToProjectHierarchy({
    projectId,
    hierarchyId,
    requestBody,
    request,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die ID des Hierarchieelements.
     */
    hierarchyId: number,
    requestBody: ProjectHierarchyItemsRequest,
    /**
     * Die Anforderungsdaten für die neuen Artikel.
     */
    request?: any,
  }): CancelablePromise<Array<ItemQuantityWithSourcePriceAndType>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/projects/{projectId}/hierarchy/{hierarchyId}/items',
      path: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
      },
      query: {
        'request': request,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Neue Artikel an Projekthierarchieelement anbinden
   * Bindet neue Artikel an ein spezifisches Hierarchieelement eines Projekts an.
   * @returns ItemQuantityWithSourcePriceAndType OK
   * @throws ApiError
   */
  public static connectNewItemsToProjectHierarchy({
    projectId,
    hierarchyId,
    requestBody,
    request,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die ID des Hierarchieelements.
     */
    hierarchyId: number,
    requestBody: ProjectHierarchyItemsRequest,
    /**
     * Die Anforderungsdaten für die neuen Artikel.
     */
    request?: any,
  }): CancelablePromise<Array<ItemQuantityWithSourcePriceAndType>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/projects/{projectId}/hierarchy/{hierarchyId}/items',
      path: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
      },
      query: {
        'request': request,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Artikel-Relation in Projekthierarchie ändern
   * Ändert die Relation eines Artikels in einer Projekthierarchie.
   * @returns ItemQuantityWithSourcePriceAndType OK
   * @throws ApiError
   */
  public static changeItemHierarchyRelation({
    projectId,
    hierarchyId,
    itemId,
    requestBody,
    source,
    request,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die ID des Hierarchieelements.
     */
    hierarchyId: number,
    /**
     * Die ID des Artikels.
     */
    itemId: number,
    requestBody: ProjectHierarchySingleItemRequest,
    /**
     * Die Quelle des Artikels (zum Beispiel Schaltschrank-Sektion).
     */
    source?: 'COUPLING_RELAY' | 'POWER_ADAPTER' | 'FEED' | 'OVERVOLTAGE' | 'FSM' | 'KA' | 'NETWORK' | 'MISCELLANEOUS' | 'CASINGS_MISC' | 'CASINGS' | 'INSTALLATION_POSITIONS' | 'DDC' | 'OTHER' | 'SERVICE',
    /**
     * Die Anforderungsdaten für die Änderung der Artikelrelation.
     */
    request?: any,
  }): CancelablePromise<ItemQuantityWithSourcePriceAndType> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/projects/{projectId}/hierarchy/{hierarchyId}/items/{itemId}',
      path: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
        'itemId': itemId,
      },
      query: {
        'source': source,
        'request': request,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Artikel-Relation in Projekthierarchie löschen
   * Löscht die Relation eines Artikels in einer Projekthierarchie.
   * @returns any OK
   * @throws ApiError
   */
  public static deleteItemHierarchyRelation({
    projectId,
    hierarchyId,
    itemId,
    source,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die ID des Hierarchieelements.
     */
    hierarchyId: number,
    /**
     * Die ID des Artikels, der gelöscht werden soll.
     */
    itemId: number,
    /**
     * Die Quelle der Artikelrelation.
     */
    source: 'COUPLING_RELAY' | 'POWER_ADAPTER' | 'FEED' | 'OVERVOLTAGE' | 'FSM' | 'KA' | 'NETWORK' | 'MISCELLANEOUS' | 'CASINGS_MISC' | 'CASINGS' | 'INSTALLATION_POSITIONS' | 'DDC' | 'OTHER' | 'SERVICE',
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/projects/{projectId}/hierarchy/{hierarchyId}/items/{itemId}',
      path: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
        'itemId': itemId,
      },
      query: {
        'source': source,
      },
    });
  }

  /**
   * Projekthierarchieelement duplizieren
   * Dupliziert ein spezifisches Hierarchieelement eines Projekts.
   * @returns ProjectHierarchyElementDTO OK
   * @throws ApiError
   */
  public static duplicateProjectHierarchy({
    projectId,
    hierarchyId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die ID des zu duplizierenden Hierarchieelements.
     */
    hierarchyId: number,
  }): CancelablePromise<Array<ProjectHierarchyElementDTO>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/projects/{projectId}/hierarchy/{hierarchyId}/duplicate',
      path: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
      },
    });
  }

  /**
   * Hierarchie eines Projekts abrufen
   * Holt die gesamte Hierarchie eines spezifischen Projekts.
   * @returns ProjectHierarchyDTO OK
   * @throws ApiError
   */
  public static getProjectHierarchy({
    projectId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
  }): CancelablePromise<ProjectHierarchyDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/projects/{projectId}/hierarchy',
      path: {
        'projectId': projectId,
      },
    });
  }

  /**
   * Hierarchieelement zu einem Projekt hinzufügen
   * Fügt ein neues Hierarchieelement zu einem spezifischen Projekt hinzu.
   * @returns ProjectHierarchyElementDTO OK
   * @throws ApiError
   */
  public static addProjectHierarchyElement({
    projectId,
    requestBody,
    request,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    requestBody: ProjectHierarchyRequest,
    /**
     * Die Anforderungsdaten für das neue Hierarchieelement.
     */
    request?: any,
  }): CancelablePromise<ProjectHierarchyElementDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/projects/{projectId}/hierarchy',
      path: {
        'projectId': projectId,
      },
      query: {
        'request': request,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Hierarchieelement in anderes Elternelement duplizieren
   * Dupliziert ein Hierarchieelement eines Projekts in ein anderes Elternelement. Das neue Elternelement muss das selbe Leven wie das jetzige Elternelement besitzen
   * @returns ProjectHierarchyElementDTO OK
   * @throws ApiError
   */
  public static duplicateHierarchyElementIntoParent({
    sourceProjectId,
    sourceHierarchyId,
    destinationProjectId,
    destinationHierarchyParentId,
  }: {
    /**
     * Die Projekt-ID der Quelle.
     */
    sourceProjectId: number,
    /**
     * Die ID des zu duplizierenden Hierarchieelements.
     */
    sourceHierarchyId: number,
    /**
     * Die Projekt-ID des Ziels.
     */
    destinationProjectId: number,
    /**
     * Die ID des Ziel-Elternelements, falls zutreffend.
     */
    destinationHierarchyParentId?: number,
  }): CancelablePromise<Array<ProjectHierarchyElementDTO>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/projects/{destinationProjectId}/hierarchy/duplicate-change-parent',
      path: {
        'destinationProjectId': destinationProjectId,
      },
      query: {
        'sourceProjectId': sourceProjectId,
        'sourceHierarchyId': sourceHierarchyId,
        'destinationHierarchyParentId': destinationHierarchyParentId,
      },
    });
  }

  /**
   * Spezifisches Hierarchieelement eines Projekts abrufen
   * Holt ein spezifisches Hierarchieelement eines Projekts anhand seiner ID.
   * @returns ProjectHierarchyElementDTO OK
   * @throws ApiError
   */
  public static getProjectHierarchyElement({
    projectId,
    hierarchyId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die ID des Hierarchieelements.
     */
    hierarchyId: number,
  }): CancelablePromise<ProjectHierarchyElementDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/projects/{projectId}/hierarchy/{hierarchyId}',
      path: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
      },
    });
  }

  /**
   * Hierarchieelement eines Projekts löschen
   * Löscht ein spezifisches Hierarchieelement aus einem Projekt.
   * @returns any OK
   * @throws ApiError
   */
  public static deleteProjectHierarchyElement({
    projectId,
    hierarchyId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die ID des zu löschenden Hierarchieelements.
     */
    hierarchyId: number,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/projects/{projectId}/hierarchy/{hierarchyId}',
      path: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
      },
    });
  }

  /**
   * Projekthierarchie bearbeiten
   * Bearbeitet ein spezifisches Hierarchieelement eines Projekts.
   * @returns ProjectHierarchyElementDTO OK
   * @throws ApiError
   */
  public static editProjectHierarchy({
    projectId,
    hierarchyId,
    requestBody,
    fields,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die ID des zu bearbeitenden Hierarchieelements.
     */
    hierarchyId: number,
    requestBody: Record<string, any>,
    /**
     * Die zu aktualisierenden Daten des Hierarchieelements.
     */
    fields?: any,
  }): CancelablePromise<ProjectHierarchyElementDTO> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/projects/{projectId}/hierarchy/{hierarchyId}',
      path: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
      },
      query: {
        'fields': fields,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Statistiken zur Projekt-Hierarchie abrufen
   * Holt statistische Daten zur Anzahl der Hierarchieelemente eines Projekts, gruppiert nach Hierarchietyp.
   * @returns number OK
   * @throws ApiError
   */
  public static getHierarchyStatistics({
    projectId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
  }): CancelablePromise<Record<string, number>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/projects/{projectId}/hierarchy/quantity',
      path: {
        'projectId': projectId,
      },
    });
  }

}
