/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type LvbMassLayoutRequest = {
  migrateFrom: Array<'NEVER' | 'NONE' | 'TMU' | 'BMD' | 'COUPLING_RELAY'>;
  migrateTo?: LvbMassLayoutRequest.migrateTo;
};

export namespace LvbMassLayoutRequest {

  export enum migrateTo {
    NEVER = 'NEVER',
    NONE = 'NONE',
    TMU = 'TMU',
    BMD = 'BMD',
    COUPLING_RELAY = 'COUPLING_RELAY',
  }


}

