/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CorrectionFactor = {
  id: CorrectionFactor.id;
  shared: boolean;
  multiplier: number;
  summand: number;
};

export namespace CorrectionFactor {

  export enum id {
    BINARY_OUTPUT = 'BINARY_OUTPUT',
    ANALOG_OUTPUT = 'ANALOG_OUTPUT',
    BINARY_INPUT = 'BINARY_INPUT',
    ANALOG_INPUT = 'ANALOG_INPUT',
    COUNTER_INPUT = 'COUNTER_INPUT',
  }


}

