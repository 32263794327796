/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Item = {
  id?: number;
  name: string;
  description?: string;
  type: Item.type;
  orderBy?: number;
  selectedForAutoLayout: boolean;
  isDiscontinued: boolean;
};

export namespace Item {

  export enum type {
    FIELD_DEVICE = 'FIELD_DEVICE',
    SWITCH_CABINET = 'SWITCH_CABINET',
    CABLE = 'CABLE',
    DDC = 'DDC',
    GLT = 'GLT',
    SERVICE = 'SERVICE',
    NOT_DEFINED = 'NOT_DEFINED',
  }


}

