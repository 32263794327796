/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Strip } from '../models/Strip';
import type { StripGroup } from '../models/StripGroup';
import type { StripRequest } from '../models/StripRequest';
import type { StripWithConstructionsDTO } from '../models/StripWithConstructionsDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StripService {

  /**
   * Alle Gruppen abrufen
   * Holt alle Streifen/Gruppen, die in der Makrobibliothek gespeichert sind.
   * @returns Strip OK
   * @throws ApiError
   */
  public static getStrips(): CancelablePromise<Array<Strip>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/macrolib/strips',
    });
  }

  /**
   * Neue Gruppe hinzufügen
   * Fügt eine neue Gruppe hinzu.
   * @returns Strip OK
   * @throws ApiError
   */
  public static addStrip({
    requestBody,
    strip,
  }: {
    requestBody: StripRequest,
    /**
     * Die Anforderungsdaten für die neue Gruppe.
     */
    strip?: any,
  }): CancelablePromise<Strip> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/macrolib/strips',
      query: {
        'strip': strip,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Gruppen mit Komponenten abrufen
   * Holt alle Gruppen inklusive ihrer zugehörigen Komponenten.
   * @returns StripWithConstructionsDTO OK
   * @throws ApiError
   */
  public static getStripsIncludeConstructions(): CancelablePromise<Array<StripWithConstructionsDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/macrolib/strips/include-constructions',
    });
  }

  /**
   * Gewerke für Gruppen abrufen
   * Ruft Gewerke für die Gruppen ab. Gruppen können den Gewerken zugeordnet werden (z.B.: Lüftung)
   * @returns StripGroup OK
   * @throws ApiError
   */
  public static getStripGroups(): CancelablePromise<Array<StripGroup>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/macrolib/strips/groups',
    });
  }

  /**
   * Gruppe entfernen
   * Entfernt eine spezifische Gruppe basierend auf ihrer ID.
   * @returns any OK
   * @throws ApiError
   */
  public static removeStrip({
    id,
  }: {
    /**
     * Die ID der zu entfernenden Gruppe.
     */
    id: number,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/macrolib/strips/{id}',
      path: {
        'id': id,
      },
    });
  }

}
