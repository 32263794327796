/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PlantGroup } from '../models/PlantGroup';
import type { PlantGroupDTO } from '../models/PlantGroupDTO';
import type { PlantSection } from '../models/PlantSection';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PlantGroupService {

  /**
   * Gewerk nach ID abrufen
   * Abrufen der Details eines spezifischen Gewerks (PlantGroup) anhand seiner ID.
   * @returns PlantGroup OK
   * @throws ApiError
   */
  public static getPlantGroupById({
    id,
  }: {
    /**
     * Die ID des Gewerks.
     */
    id: number,
  }): CancelablePromise<PlantGroup> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/macrolib/plantgroups/{id}',
      path: {
        'id': id,
      },
    });
  }

  /**
   * Gewerk aktualisieren
   * Aktualisiert die Details eines bestehenden Gewerks (PlantGroup).
   * @returns PlantGroup OK
   * @throws ApiError
   */
  public static changePlant1({
    id,
    requestBody,
  }: {
    /**
     * Die ID des zu aktualisierenden Gewerks.
     */
    id: number,
    requestBody: PlantGroupDTO,
  }): CancelablePromise<PlantGroup> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/macrolib/plantgroups/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Alle Gewerke auflisten
   * Listet alle verfügbaren Gewerke (PlantGroups) auf.
   * @returns PlantGroup OK
   * @throws ApiError
   */
  public static getPlantGroup(): CancelablePromise<Array<PlantGroup>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/macrolib/plantgroups',
    });
  }

  /**
   * Neues Gewerk hinzufügen
   * Fügt ein neues Gewerk (PlantGroup) zum System hinzu.
   * @returns PlantGroup OK
   * @throws ApiError
   */
  public static addPlantGroup({
    requestBody,
  }: {
    requestBody: PlantGroupDTO,
  }): CancelablePromise<PlantGroup> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/macrolib/plantgroups',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Alle Anlagenabschnitte auflisten
   * Listet alle verfügbaren Anlagenabschnitte (PlantSections) auf.
   * @returns PlantSection OK
   * @throws ApiError
   */
  public static getPlantSections(): CancelablePromise<Array<PlantSection>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/macrolib/plantgroups/sections',
    });
  }

}
