import {
  getAllPublicPlants,
  getConstructions,
  getStripsIncludeConstructions,
  getPublicImages,
  getPlantSections
} from '@/api'
import { BlobUrl, ConstructionSimpleDTO, PlantDTO, PlantSection, StripWithConstructionsDTO } from '@/__generated__'
import { defineStore } from 'pinia'
interface MacroLibraryState {
    publicPlants: Array<PlantDTO> | undefined,
    constructions: Array<ConstructionSimpleDTO> | undefined,
    strips: Array<StripWithConstructionsDTO> | undefined,
    images: Array<BlobUrl>,
    plantSections: Array<PlantSection> | undefined
}
const initialState: MacroLibraryState = {
  publicPlants: undefined,
  constructions: undefined,
  strips: undefined,
  images: [],
  plantSections: undefined
}

const useMacroLibraryStore = defineStore('macrolibrary', {
  state: () => {
    return initialState
  },
  actions: {
    async fetchPublicPlants () {
      this.publicPlants = await getAllPublicPlants()
    },
    async getConstructions () {
      this.constructions = await getConstructions()
    },
    async getStrips () {
      this.strips = await getStripsIncludeConstructions()
    },
    async getPublicImages () {
      this.images = await getPublicImages()
    },
    async getPlantSections () {
      this.plantSections = await getPlantSections()
    }
  }
})
export { useMacroLibraryStore }
