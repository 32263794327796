/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ItemQuantityDescriptionPriceType } from './ItemQuantityDescriptionPriceType';
import type { SwitchCabinetCalculationBasisDTO } from './SwitchCabinetCalculationBasisDTO';

export type SwitchCabinetRecommendationDTO = {
  items: Array<ItemQuantityDescriptionPriceType>;
  calculationBasis: Array<SwitchCabinetCalculationBasisDTO>;
  source: SwitchCabinetRecommendationDTO.source;
};

export namespace SwitchCabinetRecommendationDTO {

  export enum source {
    COUPLING_RELAY = 'COUPLING_RELAY',
    POWER_ADAPTER = 'POWER_ADAPTER',
    FEED = 'FEED',
    OVERVOLTAGE = 'OVERVOLTAGE',
    FSM = 'FSM',
    KA = 'KA',
    NETWORK = 'NETWORK',
    MISCELLANEOUS = 'MISCELLANEOUS',
    CASINGS_MISC = 'CASINGS_MISC',
    CASINGS = 'CASINGS',
    INSTALLATION_POSITIONS = 'INSTALLATION_POSITIONS',
    DDC = 'DDC',
    OTHER = 'OTHER',
    SERVICE = 'SERVICE',
  }


}

