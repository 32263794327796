import { defineStore } from 'pinia'
import {
  deleteProjectHierarchyElement,
  addProjectHierarchyElement,
  getProjectHierarchy,
  getItemsConnectedToProjectHierarchy,
  getHierarchyStatistics,
  getProjectHierarchyElement,
  duplicateProjectHierarchy,
  editProjectHierarchy,
  duplicateHierarchyElementIntoParent,
  getPlantItemsForHierarchyElement
} from '@/api/hierarchy'
import {
  HierarchyItemRelation,
  ItemQuantity, ItemQuantityDescriptionPriceType,
  ItemQuantityType, ItemQuantityTypeWithMetersAndPrice,
  ItemQuantityWithSourcePriceAndType,
  ProjectHierarchyElementDTO
} from '@/__generated__'
import { overwriteItemsConnectedToProjectHierarchy, applyAllRecommendations } from '@/api/switch-cabinet'

interface HierarchyNodeWithChildren extends ProjectHierarchyElementDTO {
    children?: Array<ProjectHierarchyElementDTO>
}

interface HierarchyState {
    nodes: Array<ProjectHierarchyElementDTO> | undefined,
    layers: number | undefined,
    items: Array<ItemQuantityWithSourcePriceAndType> | undefined,
    itemsLoading: boolean,
    statistics: Record<string, number> | undefined,
    isPersisting: boolean,
    nodesForSearchedProject: Array<ProjectHierarchyElementDTO> | undefined,
    lastProjectId: number | undefined,
    plantItemsForHierarchy: Array<ItemQuantityTypeWithMetersAndPrice> | undefined
}

const initialState: HierarchyState = {
  nodes: undefined,
  layers: undefined,
  items: undefined,
  statistics: undefined,
  itemsLoading: false,
  isPersisting: false,
  nodesForSearchedProject: undefined,
  lastProjectId: undefined,
  plantItemsForHierarchy: undefined
}

const useHierarchyStore = defineStore('hierarchy', {
  state: () => {
    return initialState
  },
  getters: {
    sortedNodes (state) {
      return state.nodes?.sort((a, b) => a.id - b.id)
    },
    sortedProjectNodes (state) {
      return state.nodesForSearchedProject?.sort((a, b) => a.id - b.id)
    },
    getNodeById (state) : (id: number) => ProjectHierarchyElementDTO | undefined {
      return (id: number) => {
        return state.nodes?.find((n) => n.id === id)
      }
    },
    getParentNodeById (state) : (id: number) => ProjectHierarchyElementDTO | undefined {
      return (id: number) => {
        const parentId = state.nodes?.find((n) => n.id === id)?.parentId
        if (parentId === undefined) {
          return undefined
        }
        return this.getNodeById(parentId)
      }
    },
    getNodesWithLevel (state) : (level: number) => Array<ProjectHierarchyElementDTO> {
      return (level: number) => {
        return state.nodes?.filter((n) => n.level === level) ?? []
      }
    },
    getSearchedHierarchyAsTree () : (maxVisibleLayers: number | undefined, minVisibleLayers: number | undefined, notDisabledLayer: number | undefined) => HierarchyNodeWithChildren[] {
      return (maxVisibleLayers: number | undefined, minVisibleLayers: number | undefined, notDisabledLayer: number | undefined) => {
        if (this.sortedProjectNodes === undefined) {
          return []
        }
        let data = JSON.parse(JSON.stringify(this.sortedProjectNodes.map((e) => {
          const disabled = e.level !== notDisabledLayer
          return {
            ...e, disabled
          }
        }))) as Array<HierarchyNodeWithChildren>
        // Build a Tree
        const treeData = [] as HierarchyNodeWithChildren[]
        if (!Array.isArray(data)) return treeData

        data.forEach(item => {
          item.children = undefined
        })
        if (minVisibleLayers !== undefined) {
          data = data.filter((item) => {
            return item.level > 5 - minVisibleLayers
          })
        }
        if (maxVisibleLayers !== undefined) {
          data = data.filter((item) => {
            return item.level <= maxVisibleLayers
          })
        }

        const map = {} as { [key: number]: HierarchyNodeWithChildren }
        data.forEach((item: HierarchyNodeWithChildren) => {
          map[item.id] = item
        })

        data.forEach((item: HierarchyNodeWithChildren) => {
          let parent: HierarchyNodeWithChildren | undefined
          if (item.parentId === undefined) {
            parent = undefined
          } else {
            parent = map[item.parentId] // Determine whether the pid of the item exists in the map
          }
          if (parent !== undefined) { // If it exists, it means that item is not the topmost data
            (parent.children || (parent.children = [])).push(item)
          } else {
            treeData.push(item) // If it does not exist, it is the top-level data
          }
        })
        return treeData
      }
    },
    getHierarchyAsTree () : (maxVisibleLayers: number | undefined, minVisibleLayers: number | undefined) => HierarchyNodeWithChildren[] {
      return (maxVisibleLayers: number | undefined, minVisibleLayers: number | undefined) => {
        if (this.sortedNodes === undefined) {
          return []
        }
        let data = JSON.parse(JSON.stringify(this.sortedNodes)) as Array<HierarchyNodeWithChildren>
        // Build a Tree
        const treeData = [] as HierarchyNodeWithChildren[]
        if (!Array.isArray(data)) return treeData

        data.forEach(item => {
          item.children = undefined
        })
        if (minVisibleLayers !== undefined) {
          data = data.filter((item) => {
            return item.level > 5 - minVisibleLayers
          })
        }
        if (maxVisibleLayers !== undefined) {
          data = data.filter((item) => {
            return item.level <= maxVisibleLayers
          })
        }

        const map = {} as { [key: number]: HierarchyNodeWithChildren }
        data.forEach((item: HierarchyNodeWithChildren) => {
          map[item.id] = item
        })

        data.forEach((item: HierarchyNodeWithChildren) => {
          let parent: HierarchyNodeWithChildren | undefined
          if (item.parentId === undefined) {
            parent = undefined
          } else {
            parent = map[item.parentId] // Determine whether the pid of the item exists in the map
          }
          if (parent !== undefined) { // If it exists, it means that item is not the topmost data
            (parent.children || (parent.children = [])).push(item)
          } else {
            treeData.push(item) // If it does not exist, it is the top-level data
          }
        })
        return treeData
      }
    }
  },
  actions: {
    deleteChildrenRecursive (parent: ProjectHierarchyElementDTO, hierarchy: ProjectHierarchyElementDTO[]) {
      this.isPersisting = true
      for (let i = hierarchy.length - 1; i >= 0; i--) {
        if (hierarchy[i].parentId === parent.id) {
          this.deleteChildrenRecursive(hierarchy[i], hierarchy)
          hierarchy.splice(i, 1)
        }
      }
      this.isPersisting = false
    },
    async fetchHierarchyByProjectId (projectId: number) {
      this.lastProjectId = undefined
      const data = await getProjectHierarchy(projectId)
      this.nodes = data.children
      this.lastProjectId = projectId
      this.layers = data.layers
    },
    async fetchHierarchyElement (projectId: number, hierarchyId: number) {
      if (this.nodes === undefined) {
        this.nodes = []
      }
      const element = await getProjectHierarchyElement(hierarchyId, projectId)
      this.nodes = this.nodes.filter((i: ProjectHierarchyElementDTO) => i.id !== element.id)
      this.nodes.push(element)
      this.nodes = [...this.nodes]
      return element
    },
    async fetchHierarchyItems (projectId: number, hierarchyId: number) {
      this.itemsLoading = true
      this.items = await getItemsConnectedToProjectHierarchy(hierarchyId, projectId)
      this.itemsLoading = false
    },
    async fetchHierarchyStatistic (projectId: number) {
      this.statistics = await getHierarchyStatistics(projectId)
    },
    async addHierarchyElement (projectId: number, parentId: number | undefined) {
      this.isPersisting = true
      const newItem = await addProjectHierarchyElement(projectId, {
        parentId: parentId,
        name: ''
      })
      this.nodes?.push(newItem)
      this.isPersisting = false
      return newItem
    },
    async changeHierarchyParent ({ projectId, hierarchyId, newParentId }: { projectId: number, hierarchyId: number, newParentId: number }): Promise<ProjectHierarchyElementDTO> {
      this.isPersisting = true
      const a = await editProjectHierarchy({ parent: newParentId }, hierarchyId, projectId)
      this.isPersisting = false
      // Remove old instance
      const replaceIndex = this.nodes?.findIndex((i) => i.id === hierarchyId)
      if (this.nodes !== undefined && replaceIndex !== undefined) {
        this.nodes[replaceIndex] = a
        this.nodes = [...this.nodes]
      }
      return a
    },
    async duplicateHierarchyElement (projectId: number, hierarchyId: number): Promise<number[] | undefined> {
      this.isPersisting = true
      if (this.nodes === undefined) {
        return
      }
      const copies = await duplicateProjectHierarchy(hierarchyId, projectId)
      this.nodes?.push(...copies)
      this.nodes = [...this.nodes]
      this.isPersisting = false
      return copies.map((e) => e.id)
    },
    async duplicateHierarchyElementChangeParent (
      {
        sourceProjectId,
        sourceHierarchyId,
        destinationProjectId,
        destinationHierarchyParentId
      }: {
            sourceProjectId: number,
            sourceHierarchyId: number,
            destinationProjectId: number,
            destinationHierarchyParentId?: number,
          }
    ) {
      this.isPersisting = true
      if (this.nodes === undefined) {
        return
      }
      const copies = await duplicateHierarchyElementIntoParent({
        sourceProjectId,
        sourceHierarchyId,
        destinationProjectId,
        destinationHierarchyParentId
      })
      this.isPersisting = false
      this.nodes?.push(...copies)
      this.nodes = [...this.nodes]
      this.isPersisting = false
      return copies.map((e) => e.id)
    },
    async deleteNodeById (projectId: number, hierarchyId: number) {
      this.isPersisting = true
      // delete node
      await deleteProjectHierarchyElement(hierarchyId, projectId)
      this.isPersisting = false
      const parent = this.nodes?.find((i) => i.id === hierarchyId)
      // Remove node from store
      if (this.nodes !== undefined && parent !== undefined) {
        // Delete all children recursively
        this.deleteChildrenRecursive(parent, this.nodes)
        this.nodes = this.nodes.filter((i) => i.id !== parent.id)
        this.nodes = [...this.nodes]
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async editProjectHierarchy (projectId: number, hierarchyId: number, fields: any) {
      this.isPersisting = true
      const a = await editProjectHierarchy(fields, hierarchyId, projectId)
      this.isPersisting = false
      // Remove old instance
      const replaceIndex = this.nodes?.findIndex((i) => i.id === hierarchyId)
      if (this.nodes !== undefined && replaceIndex !== undefined) {
        this.nodes[replaceIndex] = a
        this.nodes = [...this.nodes]
      }
    },
    async overwriteItemsOnSource (projectId: number, hierarchyId: number, items: ItemQuantity[], source: HierarchyItemRelation.source, addManually = false) {
      if (this.items === undefined) {
        return
      }
      this.isPersisting = true
      this.itemsLoading = true
      const a = await overwriteItemsConnectedToProjectHierarchy(hierarchyId, projectId, { items, addManually, source })
      this.isPersisting = false
      this.items = [...this.items.filter((i) => i.source !== source), ...a]
      this.itemsLoading = false
    },
    async applyAllRecommendationsFromSwitchCabinet (projectId: number, hierarchyId: number) {
      if (this.items === undefined) {
        return
      }
      this.isPersisting = true
      this.itemsLoading = true
      const a = await applyAllRecommendations(hierarchyId, projectId)
      this.isPersisting = false
      this.items = a
      this.itemsLoading = false
    },
    async loadNodesForSearchedProject (projectId: number) {
      this.nodesForSearchedProject = undefined
      this.nodesForSearchedProject = (await getProjectHierarchy(projectId)).children
    },
    async getPlantItemsForHierarchyElement ({
      projectId,
      hierarchyId
    }: {
      projectId: number,
      hierarchyId: number,
    }) {
      this.plantItemsForHierarchy = undefined
      this.plantItemsForHierarchy = await getPlantItemsForHierarchyElement({ projectId, hierarchyId })
    }
  }
})

export { useHierarchyStore }
