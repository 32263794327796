/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PageNavResource } from '../models/PageNavResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NavResourceService {

  /**
   * Navision Ressourcen suchen
   * Sucht Ressourcen in Navision basierend auf der Seitennummer, der Anzahl der Ergebnisse pro Seite und einer optionalen Suchabfrage.
   * @returns PageNavResource OK
   * @throws ApiError
   */
  public static searchForNavResources({
    page,
    pageCount = 10,
    query,
  }: {
    /**
     * Die Seite der Ergebnisse, beginnend bei 0.
     */
    page?: number,
    /**
     * Die Anzahl der Ergebnisse pro Seite.
     */
    pageCount?: number,
    /**
     * Optional. Die Abfragezeichenkette für die Suche nach Ressourcen.
     */
    query?: string,
  }): CancelablePromise<PageNavResource> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/nav/resource',
      query: {
        'page': page,
        'pageCount': pageCount,
        'query': query,
      },
    });
  }

}
