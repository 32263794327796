import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
Vue.use(Vuetify)
export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      light: {
        primary: '#F07D00',
        secondary: '#003C64',
        accent: '#2196F3'
      },
      dark: {
        primary: '#F07D00',
        secondary: '#003C64',
        accent: '#2196F3'
      }
    }
  }
})
