/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ItemDTO } from '../models/ItemDTO';
import type { ItemQuantityTypeWithMetersAndPrice } from '../models/ItemQuantityTypeWithMetersAndPrice';
import type { ItemRequest } from '../models/ItemRequest';
import type { ItemSelectedForAutoLayoutDTO } from '../models/ItemSelectedForAutoLayoutDTO';
import type { ItemUsageDTO } from '../models/ItemUsageDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ItemService {

  /**
   * Alle verfügbaren Artikel laden
   * Gibt alle verfügbaren Artikel an. Verbesserungen: Pagination einführen
   * @returns ItemDTO OK
   * @throws ApiError
   */
  public static getItems(): CancelablePromise<Array<ItemDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/items',
    });
  }

  /**
   * Neuen Artikel in der SALT-DB persistieren
   * Speichert einen neuen Artikel in der SALT-DB und stellt dabei sicher, dass sie in der ERP-Datenbank (Navision Abbild) vorhanden ist.
   * @returns ItemDTO OK
   * @throws ApiError
   */
  public static addItem({
    requestBody,
  }: {
    requestBody: ItemRequest,
  }): CancelablePromise<ItemDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/items',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Einzelnen Artikel aus der SALT-DB abrufen
   * Holt die Details eines bestimmten Artikels aus der SALT-DB anhand seiner ID.
   * @returns ItemDTO OK
   * @throws ApiError
   */
  public static getItem({
    id,
  }: {
    id: number,
  }): CancelablePromise<ItemDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/items/{id}',
      path: {
        'id': id,
      },
    });
  }

  /**
   * Artikel aus der SALT-DB löschen
   * Entfernt einen Artikel anhand seiner ID aus der SALT-DB.
   * @returns any OK
   * @throws ApiError
   */
  public static deleteItem({
    id,
  }: {
    id: number,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/items/{id}',
      path: {
        'id': id,
      },
    });
  }

  /**
   * Artikel in der SALT-DB modifizieren
   * Aktualisiert die Details eines bestehenden Artikels in der SALT-DB.
   * @returns ItemSelectedForAutoLayoutDTO OK
   * @throws ApiError
   */
  public static modifyItem({
    id,
    requestBody,
  }: {
    id: number,
    requestBody: Record<string, any>,
  }): CancelablePromise<ItemSelectedForAutoLayoutDTO> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/items/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Alle Dienstleistungen laden
   * Lädt alle Dienstleistungs-Artikel, die in der SALT-Datenbank gespeichert sind.
   * @returns ItemDTO OK
   * @throws ApiError
   */
  public static getServices(): CancelablePromise<Array<ItemDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/items/services',
    });
  }

  /**
   * Alle nicht zugeordneten Artikel laden
   * Lädt alle Artikel, die noch nicht innerhalb von SALT zugeordnet sind.
   * @returns ItemDTO OK
   * @throws ApiError
   */
  public static getOtherItems(): CancelablePromise<Array<ItemDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/items/other',
    });
  }

  /**
   * Alle GLT-Artikel laden
   * Lädt alle GLT-Artikel, die in der SALT-Datenbank gespeichert sind.
   * @returns ItemDTO OK
   * @throws ApiError
   */
  public static getGlt(): CancelablePromise<Array<ItemDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/items/glt',
    });
  }

  /**
   * Alle Feldgeräte laden
   * Lädt alle Feldgeräte, die in der SALT-Datenbank gespeichert sind Beispiel: FF5002D-02-SSB
   * @returns ItemDTO OK
   * @throws ApiError
   */
  public static getFieldDevices(): CancelablePromise<Array<ItemDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/items/fielddevices',
    });
  }

  /**
   * Alle DDC laden
   * Lädt alle DDC-Artikel, die in der SALT-Datenbank gespeichert sind Beispiel: DDC4040E
   * @returns ItemDTO OK
   * @throws ApiError
   */
  public static getDdCs(): CancelablePromise<Array<ItemDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/items/ddc',
    });
  }

  /**
   * Alle DDC laden mit Attribut 'für automatische Auslegung nutzen'
   * Lädt alle DDC-Artikel, die in der SALT-Datenbank gespeichert sind Beispiel: DDC4040E. Dazu kommt noch das Attribut, ob es für die automatische Auslegung genutzt werden soll.
   * @returns ItemSelectedForAutoLayoutDTO OK
   * @throws ApiError
   */
  public static getDdCsIncludeSelectedForAutoLayout(): CancelablePromise<Array<ItemSelectedForAutoLayoutDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/items/ddc/include-auto-layout',
    });
  }

  /**
   * Verfügbarkeit von Artikeln in SALT und NAV Datenbanken prüfen
   * Überprüft alle Artikel in der 'SALT' Datenbank und vergleicht sie mit der 'NAV' Datenbank.
   * @returns ItemUsageDTO OK
   * @throws ApiError
   */
  public static checkItemAvailability(): CancelablePromise<ItemUsageDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/items/check-item-availability',
    });
  }

  /**
   * Alle Kabel laden
   * Lädt alle Kabel, die in der SALT-Datenbank gespeichert sind Beispiel: ELK-018402
   * @returns ItemDTO OK
   * @throws ApiError
   */
  public static getCables(): CancelablePromise<Array<ItemDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/items/cables',
    });
  }

  /**
   * Alle Schaltschränke laden
   * Lädt alle Schaltschrank-Artikel, die in der SALT-Datenbank gespeichert sind Beispiel: TLD1
   * @returns ItemDTO OK
   * @throws ApiError
   */
  public static getCabinets(): CancelablePromise<Array<ItemDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/items/cabinets',
    });
  }

  /**
   * Funktionsliste für eine Anlage aus der Makrobibliothek abrufen
   * Von Admins genutzt: Ruft alle Funktionslisten für eine Anlage, die in der Makrobibliothek gespeichert ist, ab
   * @returns ItemQuantityTypeWithMetersAndPrice OK
   * @throws ApiError
   */
  public static getPlantItemsForHierarchyElement({
    projectId,
    hierarchyId,
  }: {
    /**
     * Die ID des Projekts
     */
    projectId: number,
    /**
     * Die ID des Hierarchieelements innerhalb der Anlage
     */
    hierarchyId: number,
  }): CancelablePromise<Array<ItemQuantityTypeWithMetersAndPrice>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/items/asp-plant-items',
      query: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
      },
    });
  }

}
