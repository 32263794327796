/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ItemDTO } from './ItemDTO';
import type { ProjectRole } from './ProjectRole';

export type ServiceEntryDTO = {
  id: number;
  item?: ItemDTO;
  pointType: ServiceEntryDTO.pointType;
  role: ProjectRole;
  seconds: number;
  shared: boolean;
};

export namespace ServiceEntryDTO {

  export enum pointType {
    BINARY_OUTPUT = 'BINARY_OUTPUT',
    ANALOG_OUTPUT = 'ANALOG_OUTPUT',
    BINARY_INPUT = 'BINARY_INPUT',
    ANALOG_INPUT = 'ANALOG_INPUT',
    COUNTER_INPUT = 'COUNTER_INPUT',
  }


}

