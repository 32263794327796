import { defineStore } from 'pinia'
import sanitizeHtml from 'sanitize-html'
import { getFieldLevelHelpUrl } from '@/api/files'
interface FieldLevelHelpState {
    documentation: Document | undefined,
    fieldLevelHelpUrl: string | undefined

}

const initialState: FieldLevelHelpState = {
  documentation: undefined,
  fieldLevelHelpUrl: undefined
}

const useDocsStore = defineStore('xml', {
  state: () => {
    return initialState
  },
  getters: {
    getDocByKeyAndLang (state) : ({ key, lang }: { key: string, lang: string }) => string | undefined {
      return ({ key, lang }) => {
        if (this.documentation === undefined) {
          return undefined
        }
        const query = `object[name="${key}"][lang="${lang}"]`
        const xml = state.documentation?.querySelector(query)
        if (xml === undefined || xml === null) {
          return undefined
        }
        const htmlContentAsString = xml.querySelector('content')?.textContent
        if (htmlContentAsString === null || htmlContentAsString === undefined) {
          return undefined
        }
        const el = document.createElement('html')
        el.innerHTML = htmlContentAsString
        return sanitizeHtml(el.innerHTML)
      }
    }
  },
  actions: {
    async fetchDocumentation () {
      const parser = new DOMParser()
      //
      const urlResponse = await getFieldLevelHelpUrl()
      const response = await fetch(urlResponse.url)
      const xmlString = await response.text()
      this.documentation = parser.parseFromString(xmlString, 'application/xml')
    }
  }
})
export { useDocsStore }
