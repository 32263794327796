/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type NavSelectedFilterResponse = {
  attributeId: number;
  type: NavSelectedFilterResponse.type;
  min?: number;
  max?: number;
  selectedAttributeValueId?: number;
};

export namespace NavSelectedFilterResponse {

  export enum type {
    STRING_VALUE = 'STRING_VALUE',
    ENUM_VALUE = 'ENUM_VALUE',
    INT_VALUE = 'INT_VALUE',
    FLOAT_VALUE = 'FLOAT_VALUE',
  }


}

