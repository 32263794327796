/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ProjectListItemDTO = {
  id?: number;
  name: string;
  location: string;
  user?: number;
  customer: string;
  createdAt: string;
  state: ProjectListItemDTO.state;
  isFavorite: boolean;
};

export namespace ProjectListItemDTO {

  export enum state {
    NOT_STARTED = 'NOT_STARTED',
    IN_PROGRESS = 'IN_PROGRESS',
    FINISHED = 'FINISHED',
    CANCELLED = 'CANCELLED',
    SENT_COST_ESTIMATION = 'SENT_COST_ESTIMATION',
    SENT_OFFER = 'SENT_OFFER',
    ASSIGNMENT_ACCEPTED = 'ASSIGNMENT_ACCEPTED',
    ASSIGNMENT_DENIED = 'ASSIGNMENT_DENIED',
  }


}

