import { NavItemService, NavItemCategoryService, NavResourceService } from '@/__generated__'
import { FilterOptionMinMaxValue } from '@/model/FilterOptionMinMaxValue'
import { FilterOptionStringValue } from '@/model/FilterOptionStringValue'
const getMetaFor = (id: string, isResource: boolean) => NavItemService.getMetaForItem({ id, isResource })
const getCategories = (filter?: string, parentId?: string, showAllSubcategories?: boolean) => NavItemCategoryService.getCategories({ filter, parentId, showAllSubcategories })
const findInNavController = (
  category: string | undefined,
  page: number,
  pageCount: number,
  query: string | undefined,
  filters: string[],
  vendorIds: string[] | undefined
) => NavItemService.findInNavController({
  category,
  page,
  pageCount,
  query,
  filters,
  vendorIds
})
const searchForNavResources = (page: number, pageCount: number, query: string | undefined) => NavResourceService.searchForNavResources({ page, pageCount, query })
const getFiltersForCategory = ({ itemCategoryId }: { itemCategoryId: string}) => NavItemCategoryService.getFiltersForCategory({ itemCategoryId })
export { getMetaFor, getCategories, findInNavController, searchForNavResources, getFiltersForCategory }
