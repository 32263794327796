/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ItemUsageElementDTO = {
  itemName: string;
  itemId: number;
  itemType: ItemUsageElementDTO.itemType;
  usages: Array<string>;
};

export namespace ItemUsageElementDTO {

  export enum itemType {
    FIELD_DEVICE = 'FIELD_DEVICE',
    SWITCH_CABINET = 'SWITCH_CABINET',
    CABLE = 'CABLE',
    DDC = 'DDC',
    GLT = 'GLT',
    SERVICE = 'SERVICE',
    NOT_DEFINED = 'NOT_DEFINED',
  }


}

