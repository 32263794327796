/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ConstructionRequest = {
  name: string;
  unitId?: number;
  localOverrideOperation?: ConstructionRequest.localOverrideOperation;
};

export namespace ConstructionRequest {

  export enum localOverrideOperation {
    NEVER = 'NEVER',
    NONE = 'NONE',
    TMU = 'TMU',
    BMD = 'BMD',
    COUPLING_RELAY = 'COUPLING_RELAY',
  }


}

