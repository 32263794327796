import authentication from '@/authentication'
import { APPLICATION_ID, BACKEND_URI } from '@/utils/env'
import { OpenAPI } from '@/__generated__'

OpenAPI.TOKEN = async () => authentication.acquireToken(APPLICATION_ID)
OpenAPI.BASE = BACKEND_URI

export * from './projects'
export * from './project-constructions'
export * from './function-list'
export * from './ddc'
export * from './hierarchy'
export * from './price'
export * from './asp'
export * from './macrolibrary'
export * from './nav'
export * from './services'
