/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExportDTO } from '../models/ExportDTO';
import type { ItemQuantity } from '../models/ItemQuantity';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ExportService {

  /**
   * Exportiere die Bauman-CSV als String für eine manuelle Artikelliste
   * Exportiert ein Objekt mit 'TYP' (in diesem Fall 'BAUMAN') mittels mitgegebener, fixer Artikelliste. Gibt dieses als String zurück, muss also noch im Frontend als .csv verpackt werden.
   * @returns ExportDTO OK
   * @throws ApiError
   */
  public static exportToBaumanUsingProvidedItems({
    requestBody,
    body,
  }: {
    requestBody: Array<ItemQuantity>,
    /**
     * Artikelliste bestehend aus Array aus Artikel und Anzahl, welche als Baumanager-Export exportiert werden sollen
     */
    body?: any,
  }): CancelablePromise<ExportDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/download/bauman',
      query: {
        'body': body,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Exportiere die Datenpunkt und Kabelliste für ein Projekt
   * Iteriert durch alle Anlagen und exportiert eine Datenpunkt und Kabelliste für ein Projekt als Excel-Datei mithilfe der Artikel und Funktionslisten
   * @returns binary OK
   * @throws ApiError
   */
  public static exportDatapointsAndCablesAsXlsx({
    projectId,
  }: {
    /**
     * ID des Projektes, dessen Kabelliste exportiert werden soll
     */
    projectId: number,
  }): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/download/{projectId}/datapoints-and-cables',
      path: {
        'projectId': projectId,
      },
    });
  }

  /**
   * Exportiere die Bauman-CSV als String für ein Projekt
   * Exportiert ein Objekt mit 'TYP' (in diesem Fall 'BAUMAN') aller Artikel mittels aller verfügbaren Anlagen & ASP's. Gibt dieses als String zurück, muss also noch im Frontend als .csv verpackt werden.
   * @returns ExportDTO OK
   * @throws ApiError
   */
  public static exportToBaumanDto({
    projectId,
  }: {
    /**
     * ID des Projektes, dessen Bauman-Datei exportiert werden soll
     */
    projectId: number,
  }): CancelablePromise<ExportDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/download/{projectId}/bauman/dto',
      path: {
        'projectId': projectId,
      },
    });
  }

}
