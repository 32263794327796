/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ItemSelectedForAutoLayoutDTO = {
  id: number;
  name: string;
  description?: string;
  orderBy?: number;
  type: ItemSelectedForAutoLayoutDTO.type;
  selectedForAutoLayout: boolean;
};

export namespace ItemSelectedForAutoLayoutDTO {

  export enum type {
    FIELD_DEVICE = 'FIELD_DEVICE',
    SWITCH_CABINET = 'SWITCH_CABINET',
    CABLE = 'CABLE',
    DDC = 'DDC',
    GLT = 'GLT',
    SERVICE = 'SERVICE',
    NOT_DEFINED = 'NOT_DEFINED',
  }


}

