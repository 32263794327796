/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ValveLayoutParametersResponse = {
  productCombination: ValveLayoutParametersResponse.productCombination;
  pressureCombination: ValveLayoutParametersResponse.pressureCombination;
  typeCombination: ValveLayoutParametersResponse.typeCombination;
  fasteningCombination: ValveLayoutParametersResponse.fasteningCombination;
  driveCombination: ValveLayoutParametersResponse.driveCombination;
  powerSupplyCombination: ValveLayoutParametersResponse.powerSupplyCombination;
};

export namespace ValveLayoutParametersResponse {

  export enum productCombination {
    VALVE_ONLY = 'VALVE_ONLY',
    BALL_VALVE_ONLY = 'BALL_VALVE_ONLY',
    VALVE_DRIVE_ONLY = 'VALVE_DRIVE_ONLY',
    VALVE_AND_DRIVE = 'VALVE_AND_DRIVE',
  }

  export enum pressureCombination {
    PN6 = 'PN6',
    PN10 = 'PN10',
    PN16 = 'PN16',
    PN25 = 'PN25',
    PN40 = 'PN40',
    PN63 = 'PN63',
  }

  export enum typeCombination {
    STRAIGHT_THROUGH = 'STRAIGHT_THROUGH',
    THREE_WAY = 'THREE_WAY',
    FOUR_WAY = 'FOUR_WAY',
    SIX_WAY = 'SIX_WAY',
  }

  export enum fasteningCombination {
    SCREW = 'SCREW',
    FLANGE = 'FLANGE',
    ANY = 'ANY',
  }

  export enum driveCombination {
    FAILSAFE = 'FAILSAFE',
    ANY = 'ANY',
  }

  export enum powerSupplyCombination {
    V24 = 'V24',
    V230 = 'V230',
    ANY = 'ANY',
  }


}

