/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AspDatapointDto } from '../models/AspDatapointDto';
import type { AspDatapointSumDTO } from '../models/AspDatapointSumDTO';
import type { ConnectItemsToHierarchyRequest } from '../models/ConnectItemsToHierarchyRequest';
import type { ConnectItemToHierarchyRequest } from '../models/ConnectItemToHierarchyRequest';
import type { HierarchyItemRelation } from '../models/HierarchyItemRelation';
import type { HierarchyItemRelationDTO } from '../models/HierarchyItemRelationDTO';
import type { ItemQuantity } from '../models/ItemQuantity';
import type { ItemQuantityDescriptionPriceType } from '../models/ItemQuantityDescriptionPriceType';
import type { ItemQuantityWithSource } from '../models/ItemQuantityWithSource';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AspService {

  /**
   * Alle Schaltschrank-Artikel für einen ASP abrufen
   * Gibt alle Artikel mit dem Typ 'Schaltschrank' (SWITCH_CABINET) zurück, die an einem ASP hängen. Wirft einen Fehler, wenn die Hierarchy-ID nicht auf einen ASP verweist.
   * @returns ItemQuantityDescriptionPriceType OK
   * @throws ApiError
   */
  public static getCabinetsFromHierarchy({
    id,
  }: {
    /**
     * ID des ASPs, f��r den die Schaltschränke zurückgegeben werden sollen.
     */
    id: number,
  }): CancelablePromise<Array<ItemQuantityDescriptionPriceType>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/asp/{id}/items',
      path: {
        'id': id,
      },
    });
  }

  /**
   * @deprecated
   * @returns any OK
   * @throws ApiError
   */
  public static changeItems1({
    id,
    requestBody,
  }: {
    id: number,
    requestBody: Array<ItemQuantityWithSource>,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/asp/{id}/items',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Mehrere Artikel mit einem ASP verknüpfen
   * Genutzt für Schaltschrankauslegung: Verknüpft mehrere Artikel mit einem ASP. Im Moment können nur DDC's mit ASP's verknüpft werden - in allen anderen Fällen wird ein Fehler geworfen
   * @returns ItemQuantity OK
   * @throws ApiError
   */
  public static connectMultipleItemsToDataPoint({
    requestBody,
    body,
  }: {
    requestBody: ConnectItemsToHierarchyRequest,
    /**
     * Objekt mit Artikelliste, die verknüpft werden soll, ASP-ID und Verknüpfungstyp
     */
    body?: any,
  }): CancelablePromise<Array<ItemQuantity>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/asp/connectItems',
      query: {
        'body': body,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Einen einzelnen Artikel mit einem ASP verknüpfen
   * Verknüpft einen einzelnen Artikel mit einem ASP. Im Moment können nur DDC's mit ASP's verknüpft werden - in allen anderen Fällen wird ein Fehler geworfen
   * @returns HierarchyItemRelation OK
   * @throws ApiError
   */
  public static connectItemToDataPoint({
    requestBody,
    body,
  }: {
    requestBody: ConnectItemToHierarchyRequest,
    /**
     * Objekt mit der ID des ASP's, der internen ID des Artikels (Typ: Long, Nicht der Artikelname) und Anzahl
     */
    body?: any,
  }): CancelablePromise<HierarchyItemRelation> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/asp/connectItem',
      query: {
        'body': body,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Alle DDC-Artikel für einen ASP abrufen
   * Gibt alle Artikel mit dem Typ 'DDC' zurück, die an einem ASP hängen. Wirft einen Fehler, wenn die Hierarchy-ID nicht auf einen ASP verweist.
   * @returns HierarchyItemRelationDTO OK
   * @throws ApiError
   */
  public static getDdcFromHierarchy({
    id,
  }: {
    /**
     * ID des ASPs, für den die DDC's zurückgegeben werden sollen.
     */
    id: number,
  }): CancelablePromise<Array<HierarchyItemRelationDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/asp/{id}/ddc',
      path: {
        'id': id,
      },
    });
  }

  /**
   * Datenpunkte für alle ASP's einzeln abrufen
   * Gibt die Datenpunktsumme der Datenpunkte (Binär & Analog & Anzahl der DP) aufgeteilt nach ASP's zurück
   * @returns AspDatapointDto OK
   * @throws ApiError
   */
  public static getChildrenDataPoints({
    id,
  }: {
    /**
     * Die Projekt-ID, für die die Datenpunkte in Verbindung mit den ASP's abgerufen werden soll
     */
    id: number,
  }): CancelablePromise<Array<AspDatapointDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/asp/{id}/datapoints',
      path: {
        'id': id,
      },
    });
  }

  /**
   * Datenpunkte für alle ASP's abrufen
   * Gibt die Datenpunktsumme der Datenpunkte (Binär & Analog & Anzahl der DP) aller ASP's in einem Projekt zurück
   * @returns AspDatapointSumDTO OK
   * @throws ApiError
   */
  public static getChildrenDataPointsByProject({
    projectId,
  }: {
    /**
     * Die Projekt-ID, für die die Datenpunktsumme abgerufen werden soll
     */
    projectId: number,
  }): CancelablePromise<AspDatapointSumDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/asp/datapoints',
      query: {
        'projectId': projectId,
      },
    });
  }

}
