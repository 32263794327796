export const formatPrice = (price: number): string => {
  return price.toLocaleString(['de-DE'], {
    style: 'currency',
    currency: 'EUR'
  })
}

export const formatFloatNumber = (number: number): string => {
  return number.toLocaleString(['de-DE'], {
    maximumFractionDigits: 2
  })
}

export const formatEstimationPrice = (price: number): string => {
  return Math.round(price).toLocaleString(['de-DE'], {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0
  })
}

export const formatPercentage = (absoluteValue: number): string => {
  const percentage = Math.round(absoluteValue * 100)
  if (percentage < 0) {
    return '< 0%'
  }
  return `${percentage}%`
}
