import {
  DdcService,
  AspService,
  ConnectItemToHierarchyRequest,
  ConnectItemsToHierarchyRequest,
  DdcDatapointsRealDataRequest,
  CalculateAutomaticLayoutDataRequest
} from '@/__generated__'

const getDdcItemsConnectedToProperty = (hierarchyId: number) => DdcService.getDdcItemsConnectedToProperty({ hierarchyId })
const calculateDdcDatapoints = (hierarchyId: number) => DdcService.calculateDdcDatapoints({ hierarchyId })
const getMeta = (hierarchyId: number | undefined) => DdcService.getMeta({ hierarchyId })
const getLvbMapping = (hierarchyId: number) => DdcService.getLvbMapping({ hierarchyId })
const connectItemToDataPoint = (body: ConnectItemToHierarchyRequest) => AspService.connectItemToDataPoint({ requestBody: body })
const connectMultipleItemsToDataPoint = (body: ConnectItemsToHierarchyRequest) => AspService.connectMultipleItemsToDataPoint({ requestBody: body })
const calculateAutomaticDdcLayout = (hierarchyId: number, items: Array<string>, reserve: number) => DdcService.calculateAutomaticDdcLayout({ hierarchyId, requestBody: items, reserve })
const calculateDdcDatapointsUsingProvidedData = (body: DdcDatapointsRealDataRequest) => DdcService.calculateDdcDatapointsUsingProvidedData({ requestBody: body })
const calculateAutomaticDdcLayoutUsingData = ({ requestBody }: { requestBody: CalculateAutomaticLayoutDataRequest}) => DdcService.calculateAutomaticDdcLayoutUsingData({ requestBody })
export { connectItemToDataPoint, getDdcItemsConnectedToProperty, calculateDdcDatapoints, getMeta, getLvbMapping, calculateAutomaticDdcLayout, connectMultipleItemsToDataPoint, calculateDdcDatapointsUsingProvidedData, calculateAutomaticDdcLayoutUsingData }
