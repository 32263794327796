import { render, staticRenderFns } from "./ProjectListComponent.vue?vue&type=template&id=28ae57d6&scoped=true&"
import script from "./ProjectListComponent.vue?vue&type=script&setup=true&lang=ts&"
export * from "./ProjectListComponent.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./ProjectListComponent.vue?vue&type=style&index=0&id=28ae57d6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28ae57d6",
  null
  
)

export default component.exports