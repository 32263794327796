/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ItemQuantity } from '../models/ItemQuantity';
import type { RoleSecondsDTO } from '../models/RoleSecondsDTO';
import type { ServiceCalculation } from '../models/ServiceCalculation';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ManualServiceShoppingService {

  /**
   * Dienstleistungseintrag ändern
   * Ändert die Anzahl eines Dienstleistungseintrages in einem Projekt und Hierarchieelement.
   * @returns ServiceCalculation OK
   * @throws ApiError
   */
  public static modifyServiceEntry({
    projectId,
    hierarchyId,
    requestBody,
  }: {
    projectId: number,
    hierarchyId: number,
    requestBody: ItemQuantity,
  }): CancelablePromise<ServiceCalculation> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/services/manual',
      query: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Dienstleistungseintrag hinzufügen
   * Fügt einen neuen Dienstleistungseintrag mit Artikelanzahl zu einem Projekt und Hierarchieelement hinzu.
   * @returns ServiceCalculation OK
   * @throws ApiError
   */
  public static addServiceEntry({
    projectId,
    hierarchyId,
    requestBody,
  }: {
    projectId: number,
    hierarchyId: number,
    requestBody: ItemQuantity,
  }): CancelablePromise<ServiceCalculation> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/services/manual',
      query: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Dienstleistungseintrag löschen
   * Löscht einen Dienstleistungseintrag basierend auf Projekt-, Hierarchie- und Artikelnamen.
   * @returns any OK
   * @throws ApiError
   */
  public static deleteServiceEntry({
    projectId,
    hierarchyId,
    itemName,
  }: {
    projectId: number,
    hierarchyId: number,
    itemName: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/services/manual',
      query: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
        'itemName': itemName,
      },
    });
  }

  /**
   * Rollenwert eines Dienstleistungseintrags ändern
   * Ändert den Rollenwert eines Dienstleistungseintrags für ein spezifisches Projekt und Hierarchieelement.Die Rollen sind im [ProjectRoleController] zu verwalten
   * @returns RoleSecondsDTO OK
   * @throws ApiError
   */
  public static changeRoleValue({
    projectId,
    hierarchyId,
    itemId,
    roleId,
    requestBody,
  }: {
    projectId: number,
    hierarchyId: number,
    itemId: string,
    roleId: string,
    requestBody: number,
  }): CancelablePromise<RoleSecondsDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/services/manual/role',
      query: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
        'itemId': itemId,
        'roleId': roleId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Rollenwert eines Dienstleistungseintrags löschen
   * Löscht den Rollenwert eines Dienstleistungseintrags für ein spezifisches Projekt und Hierarchieelement.
   * @returns any OK
   * @throws ApiError
   */
  public static deleteRoleValue({
    projectId,
    hierarchyId,
    itemId,
    roleId,
  }: {
    projectId: number,
    hierarchyId: number,
    itemId: string,
    roleId: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/services/manual/role',
      query: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
        'itemId': itemId,
        'roleId': roleId,
      },
    });
  }

  /**
   * Angepassten Status eines Dienstleistungseintrags ändern
   * Ändert den angepassten Status eines Dienstleistungseintrags für ein bestimmtes Artikel- und Hierarchieelement.Angepasst meint dabei, ob der Dienstleistungseintrag manuell angelegt wurde oder durch die Dienstleistungsauslegung automatisiert angelegt wurde.
   * @returns ServiceCalculation OK
   * @throws ApiError
   */
  public static changeCustomStatus({
    projectId,
    hierarchyId,
    itemId,
    customized,
  }: {
    projectId: number,
    hierarchyId: number,
    itemId: string,
    customized: boolean,
  }): CancelablePromise<ServiceCalculation> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/services/manual/customized',
      query: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
        'itemId': itemId,
        'customized': customized,
      },
    });
  }

}
