import {
  deleteItemHierarchyRelation,
  getMeta,
  getLvbMapping,
  calculateDdcDatapoints,
  connectItemToDataPoint,
  calculateAutomaticDdcLayout,
  connectMultipleItemsToDataPoint,
  calculateDdcDatapointsUsingProvidedData, calculateAutomaticDdcLayoutUsingData, calculateDdcDatapointsUsingProjectId
} from '@/api'
import {
  AspDatapointDto,
  AutomaticDDCCalculationDTO, CalculateAutomaticLayoutDataRequest,
  DdcDatapointsDTO, DdcDatapointsDtoWithHierarchyId, DdcDatapointsElementDTO,
  DdcDtoQuantity,
  HierarchyItemRelation,
  RequiredLvbDTO
} from '@/__generated__'
import { defineStore } from 'pinia'
import { $t } from '@/utils/use-i18n'
import sanitizeHtml from 'sanitize-html'

interface DdcState {
    meta: Array<DdcDtoQuantity> | undefined,
    lvb: Array<RequiredLvbDTO> | undefined,
    datapoints: DdcDatapointsDTO | undefined,
    automaticLayout: AutomaticDDCCalculationDTO | undefined,
    selectedDdcItems: Array<string>,
    datapointsForProjectId: DdcDatapointsDtoWithHierarchyId[] | undefined
}

type DdcDatapointSum = {
    available: number,
    required: number,
    reserve: number
}

type DdcDatapointTableElement = {
    aspId: number,
    numPlants: number,
    numControlLoops: {
      required: number
      reserve: number,
    },
    ae: {
      required: number
      reserve: number,
    },
    aa: {
      required: number
      reserve: number,
    },
    be: {
      required: number
      reserve: number,
    },
    ba: {
      required: number
      reserve: number,
    },
    ioReservePercentage: number,
    isIoReserveAbsolute: boolean
}

const initialState: DdcState = {
  meta: undefined,
  lvb: undefined,
  datapoints: undefined,
  automaticLayout: undefined,
  selectedDdcItems: [],
  datapointsForProjectId: undefined
}

function summarizeIos (datapoints: DdcDatapointsElementDTO): number {
  return datapoints.aa + datapoints.ae + datapoints.ba + datapoints.be
}

const useDdcStore = defineStore('ddc', {
  state: () => {
    return initialState
  },
  getters: {
    summarizeDatapoints (): DdcDatapointSum | undefined {
      if (this.datapoints === undefined) {
        return undefined
      }
      return {
        available: this.datapoints.availableDatapoints.aa + this.datapoints.availableDatapoints.ae + this.datapoints.availableDatapoints.ba + this.datapoints.availableDatapoints.be,
        required: this.datapoints.requiredDatapoints.aa + this.datapoints.requiredDatapoints.ae + this.datapoints.requiredDatapoints.ba + this.datapoints.requiredDatapoints.be,
        reserve: this.datapoints.reserveDatapoints.aa + this.datapoints.reserveDatapoints.ae + this.datapoints.reserveDatapoints.ba + this.datapoints.reserveDatapoints.be
      }
    },
    getDdcDatapointsForProjectAsList (): DdcDatapointTableElement[] | undefined {
      if (this.datapointsForProjectId === undefined) {
        return undefined
      }
      return this.datapointsForProjectId.map((e) => {
        let isIoReserveAbsolute = true
        let ioReservePercentage = summarizeIos(e.datapoints.reserveDatapoints) / summarizeIos(e.datapoints.requiredDatapoints)
        if (isNaN(ioReservePercentage) || !isFinite(ioReservePercentage)) {
          isIoReserveAbsolute = false
          ioReservePercentage = summarizeIos(e.datapoints.reserveDatapoints)
        }
        return {
          aspId: e.hierarchyId,
          numPlants: e.numPlants,
          numControlLoops: {
            required: e.datapoints.requiredDatapoints.max,
            reserve: e.datapoints.reserveDatapoints.max
          },
          ae: {
            required: e.datapoints.requiredDatapoints.ae,
            reserve: e.datapoints.reserveDatapoints.ae
          },
          aa: {
            required: e.datapoints.requiredDatapoints.aa,
            reserve: e.datapoints.reserveDatapoints.aa
          },
          be: {
            required: e.datapoints.requiredDatapoints.be,
            reserve: e.datapoints.reserveDatapoints.be
          },
          ba: {
            required: e.datapoints.requiredDatapoints.ba,
            reserve: e.datapoints.reserveDatapoints.ba
          },
          ioReservePercentage,
          isIoReserveAbsolute
        }
      })
    }
  },
  actions: {
    async applyAutomaticDdcLayout (hierarchyId: number) {
      // We want to set all ddc items default to 0, but want to keep the automatic calculation.
      if (this.meta === undefined || this.automaticLayout === undefined) {
        throw Error('Either meta or automatic layout has not loaded yet.')
      }
      const quantities = this.meta.map((e) => {
        const currentQuantity = this.automaticLayout?.itemQuantities.find((f) => f.name === e.name)?.quantity ?? 0
        return { name: e.name, quantity: currentQuantity }
      })
      const newQuantities = await connectMultipleItemsToDataPoint(
        {
          hierarchyId: hierarchyId,
          itemList: quantities,
          source: HierarchyItemRelation.source.DDC
        }
      )
      for (let i = 0; i < this.meta.length; i++) {
        const metaObject = this.meta[i]
        this.meta[i].quantity = newQuantities.find((f) => f.name === metaObject.name)?.quantity ?? metaObject.quantity
      }
    },
    async applyAutomaticDdcLayoutLocally () {
      // We want to set all ddc items default to 0, but want to keep the automatic calculation.
      if (this.meta === undefined || this.automaticLayout === undefined) {
        throw Error('Either meta or automatic layout has not loaded yet.')
      }
      const quantities = this.meta.map((e) => {
        const currentQuantity = this.automaticLayout?.itemQuantities.find((f) => f.name === e.name)?.quantity ?? 0
        return { name: e.name, quantity: currentQuantity }
      })
      for (let i = 0; i < this.meta.length; i++) {
        const metaObject = this.meta[i]
        this.meta[i].quantity = quantities.find((f) => f.name === metaObject.name)?.quantity ?? metaObject.quantity
      }
    },
    async calculateAutomaticDdcLayout (hierarchyId: number, items: Array<string>, reserve: number) {
      this.automaticLayout = undefined
      this.automaticLayout = await calculateAutomaticDdcLayout(hierarchyId, items, reserve)
    },
    async calculateAutomaticLayoutLocally (requestBody: CalculateAutomaticLayoutDataRequest) {
      this.automaticLayout = undefined
      this.automaticLayout = await calculateAutomaticDdcLayoutUsingData({ requestBody })
    },
    async fetchDdcMeta (hierarchyId: number | undefined) {
      this.selectedDdcItems = []
      this.meta = undefined
      this.meta = await getMeta(hierarchyId)
      this.selectedDdcItems = this.meta.filter((i) => i.selectedForAutoLayout).map((e) => e.name)
    },
    async fetchLvb (hierarchyId: number) {
      this.lvb = await getLvbMapping(hierarchyId)
    },
    async calculateDdcDatapointsLocally (datapoints: AspDatapointDto) {
      this.datapoints = undefined
      this.datapoints = await calculateDdcDatapointsUsingProvidedData(
        {
          itemList: this.meta?.filter((i) => i.quantity > 0).map((e) => {
            return {
              name: e.name,
              quantity: e.quantity
            }
          }) ?? [],
          datapoints
        }
      )
    },
    async calculateDdcDatapoints (hierarchyId: number) {
      this.datapoints = undefined
      this.datapoints = await calculateDdcDatapoints(hierarchyId)
    },
    async changeItemQuantityLocally (itemId: number, quantity: number) {
      if (this.meta === undefined) {
        return
      }
      const i = this.meta?.findIndex((i) => i.id === itemId)
      if (i === -1) {
        return
      }
      this.meta[i].quantity = quantity
    },
    async changeItemQuantity (hierarchyId: number, itemId: number, quantity: number) {
      if (this.meta === undefined) {
        return
      }
      const i = this.meta?.findIndex((i) => i.id === itemId)
      if (i === -1) {
        return
      }
      const q = await connectItemToDataPoint({ hierarchyId, itemId, quantity })
      this.meta[i].quantity = q.quantity
    },
    async deleteItemFromHierarchyElement (hierarchyId: number, itemId: number, projectId: number) {
      if (this.meta === undefined) {
        return
      }
      const i = this.meta?.findIndex((i) => i.id === itemId)
      if (i === -1) {
        return
      }
      await deleteItemHierarchyRelation(hierarchyId, itemId, projectId, HierarchyItemRelation.source.DDC)
      this.meta[i].quantity = 0
    },
    async loadDdcDatapointTable (projectId: number) {
      this.datapointsForProjectId = undefined
      this.datapointsForProjectId = await calculateDdcDatapointsUsingProjectId(projectId)
    }
  }
})
export { useDdcStore }
