/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SwitchCabinetCalculationBasisDTO = {
  type: SwitchCabinetCalculationBasisDTO.type;
  value: number;
};

export namespace SwitchCabinetCalculationBasisDTO {

  export enum type {
    FSM_BINARY_OUTPUTS = 'FSM_BINARY_OUTPUTS',
    FSM_ANALOG_OUTPUTS = 'FSM_ANALOG_OUTPUTS',
    KA_CASSETTE_WIDTH = 'KA_CASSETTE_WIDTH',
    NETWORK_DDC_AND_TPC = 'NETWORK_DDC_AND_TPC',
    POWER_ADAPTER_AC230V = 'POWER_ADAPTER_AC230V',
    POWER_ADAPTER_AC24V = 'POWER_ADAPTER_AC24V',
    POWER_ADAPTER_DC24V = 'POWER_ADAPTER_DC24V',
    POWER_ADAPTER_DC12V = 'POWER_ADAPTER_DC12V',
    FEED_MINIMUM_PERFORMANCE = 'FEED_MINIMUM_PERFORMANCE',
    CASINGS_DP_SUM = 'CASINGS_DP_SUM',
    CASINGS_RAIL_WIDTH = 'CASINGS_RAIL_WIDTH',
  }


}

