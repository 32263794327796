/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { NavFilterOptionEntry } from './NavFilterOptionEntry';

export type NavFilterDTO = {
  id: number;
  name: string;
  type: NavFilterDTO.type;
  entries: Array<NavFilterOptionEntry>;
  unit: string;
};

export namespace NavFilterDTO {

  export enum type {
    STRING_VALUE = 'STRING_VALUE',
    ENUM_VALUE = 'ENUM_VALUE',
    INT_VALUE = 'INT_VALUE',
    FLOAT_VALUE = 'FLOAT_VALUE',
  }


}

