import authentication from '@/authentication'
import { APPLICATION_ID } from '@/utils/env'
import { useUserStore } from '@/store/users.store'

export const fetchWrapper = {
  get: request('GET'),
  post: request('POST'),
  put: request('PUT'),
  delete: request('DELETE')
}

function request (method: string) {
  return async (url: string, body: string | undefined = undefined) => {
    const header = new Headers(await getAuthHeader())
    header.set('Content-Type', 'application/json')
    header.set('Accept', 'application/json, text/plain, */*')
    const requestOptions: RequestInit = {
      method,
      headers: header
    }
    if (body) {
      requestOptions.body = JSON.stringify(body)
    }
    const response = await fetch(url, requestOptions)
    return await handleResponse(response)
  }
}

// helper functions

async function getAuthHeader () : Promise<HeadersInit> {
  try {
    const token = await authentication.acquireToken(APPLICATION_ID)
    return { Authorization: `Bearer ${token}` }
  } catch (e: unknown) {
    return {}
  }
}

async function handleResponse (response: Response) : Promise<unknown> {
  const text = await response.text()
  const data = text && JSON.parse(text)
  if (!response.ok) {
    const { user, logout } = useUserStore()
    if ([401, 403].includes(response.status) && user) {
      // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      logout()
    }

    const error = (data && data.message) || response.statusText
    return Promise.reject(error)
  }
  return data
}
