import {
  GetPostParametersRequest,
  ValveCircuitRequest,
  ValveService,
  ValveItemShopRequest, ValveKvsRequest,
  ValvePreLayoutParameters
} from '@/__generated__'
const getKvsToDnMappings = () => ValveService.getKvsToDnMappings()
const generateValveShopParameters = ({ requestBody }: {requestBody:ValveItemShopRequest }) => ValveService.generateValveShopParameters({ requestBody })
const generateParametersUsingCircuits = ({ requestBody } : {requestBody:ValveCircuitRequest}) => ValveService.generateParametersUsingCircuits({ requestBody })
const generateKvsValues = ({ requestBody } : {requestBody:ValveKvsRequest}) => ValveService.generateKvsValues({ requestBody })
const getPostVariables = ({ requestBody }: {requestBody: GetPostParametersRequest}) => ValveService.getPostVariables({ requestBody })
export { getKvsToDnMappings, generateValveShopParameters, generateParametersUsingCircuits, generateKvsValues, getPostVariables }
