/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ProjectHierarchyElementDTO = {
  id: number;
  name?: string;
  fallbackName?: string;
  level: number;
  parentId?: number;
  hierarchyType: ProjectHierarchyElementDTO.hierarchyType;
  lastServiceCalculationMethod?: ProjectHierarchyElementDTO.lastServiceCalculationMethod;
};

export namespace ProjectHierarchyElementDTO {

  export enum hierarchyType {
    PROPERTY = 'PROPERTY',
    BUILDING = 'BUILDING',
    BUILDING_SECTION = 'BUILDING_SECTION',
    ASP = 'ASP',
    PLANT = 'PLANT',
  }

  export enum lastServiceCalculationMethod {
    DATAPOINTS = 'DATAPOINTS',
    REFERENCE_TIME = 'REFERENCE_TIME',
  }


}

