/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Project } from './Project';
import type { ProjectPlant } from './ProjectPlant';

export type ProjectHierarchy = {
  id: number;
  name?: string;
  placeholder: boolean;
  project: Project;
  projectPlant?: ProjectPlant;
  lastServiceCalculationMethod?: ProjectHierarchy.lastServiceCalculationMethod;
  level: number;
  hierarchyType: ProjectHierarchy.hierarchyType;
  parentID?: number;
};

export namespace ProjectHierarchy {

  export enum lastServiceCalculationMethod {
    DATAPOINTS = 'DATAPOINTS',
    REFERENCE_TIME = 'REFERENCE_TIME',
  }

  export enum hierarchyType {
    PROPERTY = 'PROPERTY',
    BUILDING = 'BUILDING',
    BUILDING_SECTION = 'BUILDING_SECTION',
    ASP = 'ASP',
    PLANT = 'PLANT',
  }


}

