/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConstructionDTO } from '../models/ConstructionDTO';
import type { ConstructionRequest } from '../models/ConstructionRequest';
import type { ConstructionSimpleDTO } from '../models/ConstructionSimpleDTO';
import type { CustomConstructionIdDTO } from '../models/CustomConstructionIdDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConstructionsService {

  /**
   * Rufe Details über eine Komponente ab
   * Rufe Details über eine Komponente ab (Beschreibung, Kategorien, Eigenschaften ...)
   * @returns ConstructionDTO OK
   * @throws ApiError
   */
  public static getConstructionById({
    id,
  }: {
    /**
     * ID der Komponenten, dessen Details abgerufen werden sollen
     */
    id: number,
  }): CancelablePromise<ConstructionDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/macrolib/constructions/{id}',
      path: {
        'id': id,
      },
    });
  }

  /**
   * Ändere Attribute einer Komponente
   * Nur möglich mit Admin-Rechten: Ändere Attribute ab. Im Moment können hierbei nur der Name und die Einheit geändert werden. Sollte bei Gelegenheit erweitert / Deprecated werden. Besser 'updateConstruction' mit PATCH nutzen.
   * @returns ConstructionDTO OK
   * @throws ApiError
   */
  public static updateConstruction({
    id,
    requestBody,
    body,
  }: {
    /**
     * ID der Komponente, die geändert werden soll
     */
    id: number,
    requestBody: ConstructionRequest,
    /**
     * Attribute der Baugruppe. Im Moment werden nur Name und Einheit geändert.
     */
    body?: any,
  }): CancelablePromise<ConstructionDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/macrolib/constructions/{id}',
      path: {
        'id': id,
      },
      query: {
        'body': body,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Ändere jegliche Attribute einer Komponente
   * Nur möglich mit Admin-Rechten: Ändere Attribute ab. Mittels ReflectionUtils von java werden jegliche Felder abgeändert, die mitgegeben werden und sich im Objekt 'Construction' befinden. Hier wäre es sinnvoll, die 'fields' als Typisierung einzuführen (im Moment können jegliche Requests geschickt werden)
   * @returns ConstructionDTO OK
   * @throws ApiError
   */
  public static patchConstruction({
    id,
    requestBody,
    fields,
  }: {
    id: number,
    requestBody: Record<string, any>,
    /**
     * Ein Objekt mit Werten, die mit den Attributen von [Construction] übereinstimmen. Hier sollte Typisierung eingeführt werden.
     */
    fields?: any,
  }): CancelablePromise<ConstructionDTO> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/macrolib/constructions/{id}',
      path: {
        'id': id,
      },
      query: {
        'fields': fields,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Gibt die ID der aktuellen, 'Benutzerdefinierten Komponente zurück'
   * Hole die ID der 'Benutzerdefinierten Komponente'. Nur eine Komponente kann benutzerdefiniert sein. Sinn dabei: Die Benutzerdefinierte Komponente wird mit Funktionsliste bestückt und wird instanziiert, sobald ein Nutzer eine 'leere Komponente' in Projekten anlegt, sozusagen ein 'Fallback'.
   * @returns CustomConstructionIdDTO OK
   * @throws ApiError
   */
  public static getCustomConstructionId(): CancelablePromise<CustomConstructionIdDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/macrolib/constructions/custom-construction-id',
    });
  }

  /**
   * Setze die 'Benutzerdefinierte Komponente'
   * Nur möglich mit Admin-Rechten: Setze die 'Benutzerdefinierte Komponente'. Nur eine Komponente kann benutzerdefiniert sein. Sinn dabei: Die Benutzerdefinierte Komponente wird mit Funktionsliste bestückt und wird instanziiert, sobald ein Nutzer eine 'leere Komponente' in Projekten anlegt, sozusagen ein 'Fallback'.
   * @returns CustomConstructionIdDTO OK
   * @throws ApiError
   */
  public static setCustomConstructionId({
    constructionId,
  }: {
    /**
     * Die ID der Komponente, die als 'Benutzerdefinierte Komponente' markiert werden soll.
     */
    constructionId: number,
  }): CancelablePromise<CustomConstructionIdDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/macrolib/constructions/custom-construction-id',
      query: {
        'constructionId': constructionId,
      },
    });
  }

  /**
   * Rufe alle verfügbaren Komponenten ab
   * Rufe alle (auch noch nicht releaste) Komponenten in der Makrobibliothek auf. Genutzt von Admins der Makrobibliothek.
   * @returns ConstructionSimpleDTO OK
   * @throws ApiError
   */
  public static getConstructions(): CancelablePromise<Array<ConstructionSimpleDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/macrolib/constructions',
    });
  }

  /**
   * Fügt eine Komponente in der Makrobibliothek hinzu
   * Nur möglich mit Admin-Rechten: Fügt eine Komponente mit Namen, optional Einheit (siehe [specification-controller]) und LVB (Lokale Vorrangbedienung) hinzu
   * @returns ConstructionDTO OK
   * @throws ApiError
   */
  public static addConstruction({
    requestBody,
    body,
  }: {
    requestBody: ConstructionRequest,
    /**
     * Namen der Komponente, optional Einheit und optional LVB (Lokale Vorrangbedienung)
     */
    body?: any,
  }): CancelablePromise<ConstructionDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/macrolib/constructions',
      query: {
        'body': body,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Rufe alle nutzbaren Komponenten ab
   * Rufe alle nutzbaren Komponenten in der Makrobibliothek auf: Hier werden noch nicht releaste Komponenten rausgefiltert. Genutzt für das Frontend der Nutzer.
   * @returns ConstructionSimpleDTO OK
   * @throws ApiError
   */
  public static getVisibleConstructions(): CancelablePromise<Array<ConstructionSimpleDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/macrolib/constructions/public',
    });
  }

}
