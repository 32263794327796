/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ItemQuantity } from './ItemQuantity';

export type ProjectHierarchyItemsRequest = {
  items: Array<ItemQuantity>;
  source?: ProjectHierarchyItemsRequest.source;
  addManually: boolean;
};

export namespace ProjectHierarchyItemsRequest {

  export enum source {
    COUPLING_RELAY = 'COUPLING_RELAY',
    POWER_ADAPTER = 'POWER_ADAPTER',
    FEED = 'FEED',
    OVERVOLTAGE = 'OVERVOLTAGE',
    FSM = 'FSM',
    KA = 'KA',
    NETWORK = 'NETWORK',
    MISCELLANEOUS = 'MISCELLANEOUS',
    CASINGS_MISC = 'CASINGS_MISC',
    CASINGS = 'CASINGS',
    INSTALLATION_POSITIONS = 'INSTALLATION_POSITIONS',
    DDC = 'DDC',
    OTHER = 'OTHER',
    SERVICE = 'SERVICE',
  }


}

