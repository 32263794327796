/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SpecificationValue = {
  id?: number;
  minimalValue?: number;
  maximalValue?: number;
  stringValue?: string;
  isDefaultValue: boolean;
  specificationType: SpecificationValue.specificationType;
};

export namespace SpecificationValue {

  export enum specificationType {
    NUMERIC = 'NUMERIC',
    DECIMAL = 'DECIMAL',
    RANGE = 'RANGE',
    TEXT = 'TEXT',
    VARIANT = 'VARIANT',
  }


}

