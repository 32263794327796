/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ItemQuantityWithSourcePriceAndType } from '../models/ItemQuantityWithSourcePriceAndType';
import type { SwitchCabinetRecommendationDTO } from '../models/SwitchCabinetRecommendationDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SwitchCabinetService {

  /**
   * Alle Empfehlungen anwenden
   * Wendet alle Schaltschrankempfehlungen für ein bestimmtes Projekt und eine Hierarchie-ID an (Zauberstab).
   * @returns ItemQuantityWithSourcePriceAndType OK
   * @throws ApiError
   */
  public static applyAllRecommendations({
    projectId,
    hierarchyId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die Hierarchie-ID.
     */
    hierarchyId: number,
  }): CancelablePromise<Array<ItemQuantityWithSourcePriceAndType>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/cabinets/apply-all-recommendations',
      query: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
      },
    });
  }

  /**
   * Spannungsversorgung-Vorschläge abrufen
   * Holt Vorschläge für Spannungsversorgung basierend auf einem Projekt und einer Hierarchie-ID.
   * @returns SwitchCabinetRecommendationDTO OK
   * @throws ApiError
   */
  public static getPowerAdapters({
    projectId,
    hierarchyId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die Hierarchie-ID.
     */
    hierarchyId: number,
  }): CancelablePromise<SwitchCabinetRecommendationDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/cabinets/power-adapters',
      query: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
      },
    });
  }

  /**
   * Überspannungsschutz-Vorschläge abrufen
   * Holt Vorschläge für Überspannungsschutz basierend auf einem Projekt und einer Hierarchie-ID.
   * @returns SwitchCabinetRecommendationDTO OK
   * @throws ApiError
   */
  public static getOvervoltageProtection({
    projectId,
    hierarchyId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die Hierarchie-ID.
     */
    hierarchyId: number,
  }): CancelablePromise<SwitchCabinetRecommendationDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/cabinets/overvoltage-protection',
      query: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
      },
    });
  }

  /**
   * Netzwerkkomponenten-Vorschläge abrufen
   * Holt Vorschläge für Netzwerkkomponenten basierend auf einem Projekt und einer Hierarchie-ID.
   * @returns SwitchCabinetRecommendationDTO OK
   * @throws ApiError
   */
  public static getNetwork({
    projectId,
    hierarchyId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die Hierarchie-ID.
     */
    hierarchyId: number,
  }): CancelablePromise<SwitchCabinetRecommendationDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/cabinets/network',
      query: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
      },
    });
  }

  /**
   * Zubehör-Vorschläge abrufen
   * Holt Vorschläge für Zubehör für Komponenten basierend auf einem Projekt und einer Hierarchie-ID.
   * @returns SwitchCabinetRecommendationDTO OK
   * @throws ApiError
   */
  public static getMisc({
    projectId,
    hierarchyId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die Hierarchie-ID.
     */
    hierarchyId: number,
  }): CancelablePromise<SwitchCabinetRecommendationDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/cabinets/misc',
      query: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
      },
    });
  }

  /**
   * KA-Rahmen-Vorschläge abrufen
   * Holt Vorschläge für KA-Rahmen basierend auf einem Projekt und einer Hierarchie-ID.
   * @returns SwitchCabinetRecommendationDTO OK
   * @throws ApiError
   */
  public static getKaFrames({
    projectId,
    hierarchyId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die Hierarchie-ID.
     */
    hierarchyId: number,
  }): CancelablePromise<SwitchCabinetRecommendationDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/cabinets/ka',
      query: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
      },
    });
  }

  /**
   * Installationspositionen-Vorschläge / NU-Dienstleistungsvorschläge abrufen
   * Holt Vorschläge für Installationspositionen / NU-Dienstleistungsvorschläge basierend auf einem Projekt und einer Hierarchie-ID.
   * @returns SwitchCabinetRecommendationDTO OK
   * @throws ApiError
   */
  public static getInstallationPositions({
    projectId,
    hierarchyId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die Hierarchie-ID.
     */
    hierarchyId: number,
  }): CancelablePromise<SwitchCabinetRecommendationDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/cabinets/installation-positions',
      query: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
      },
    });
  }

  /**
   * LVB-Vorschläge abrufen. Bitte Route umbenennen.
   * Holt Vorschläge für LVB-Auslegung (lokale Vorrangbedienung). Die Methode sollte bei Gelegenheit umbenannt werden!
   * @returns SwitchCabinetRecommendationDTO OK
   * @throws ApiError
   */
  public static getFsm({
    projectId,
    hierarchyId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die Hierarchie-ID.
     */
    hierarchyId: number,
  }): CancelablePromise<SwitchCabinetRecommendationDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/cabinets/fsm',
      query: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
      },
    });
  }

  /**
   * Einspeisungs-Vorschläge abrufen
   * Holt Vorschläge für die Einspeisung basierend auf einem Projekt und einer Hierarchie-ID.
   * @returns SwitchCabinetRecommendationDTO OK
   * @throws ApiError
   */
  public static getFeed({
    projectId,
    hierarchyId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die Hierarchie-ID.
     */
    hierarchyId: number,
  }): CancelablePromise<SwitchCabinetRecommendationDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/cabinets/feed',
      query: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
      },
    });
  }

  /**
   * Kopplungsrelais-Vorschläge abrufen
   * Holt Vorschläge für Kopplungsrelais basierend auf einem Projekt und einer Hierarchie-ID.
   * @returns SwitchCabinetRecommendationDTO OK
   * @throws ApiError
   */
  public static getCouplingRelays({
    projectId,
    hierarchyId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die Hierarchie-ID.
     */
    hierarchyId: number,
  }): CancelablePromise<SwitchCabinetRecommendationDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/cabinets/coupling-relays',
      query: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
      },
    });
  }

  /**
   * Gehäusevorschläge abrufen
   * Holt Vorschläge für Gehäuse basierend auf einem Projekt, einer Hierarchie-ID und einem optionalen Gehäusemargenwert.
   * @returns SwitchCabinetRecommendationDTO OK
   * @throws ApiError
   */
  public static getCasings({
    projectId,
    hierarchyId,
    casingsMargin = 1.3,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die Hierarchie-ID.
     */
    hierarchyId: number,
    /**
     * Optional. Der Margenwert für die Gehäuse.
     */
    casingsMargin?: number,
  }): CancelablePromise<SwitchCabinetRecommendationDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/cabinets/casings',
      query: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
        'casingsMargin': casingsMargin,
      },
    });
  }

  /**
   * Zubehör-Vorschläge je ASP für Gehäuse abrufen
   * Holt Vorschläge für Gehäusezubehör für Komponenten basierend auf einem Projekt und einer Hierarchie-ID.
   * @returns SwitchCabinetRecommendationDTO OK
   * @throws ApiError
   */
  public static getCasingsMisc({
    projectId,
    hierarchyId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die Hierarchie-ID.
     */
    hierarchyId: number,
  }): CancelablePromise<SwitchCabinetRecommendationDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/cabinets/casings-misc',
      query: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
      },
    });
  }

  /**
   * Alle Empfehlungen abrufen
   * Holt alle Vorschläge in verschiedenen Gruppen für den Schaltschrankassistenten.
   * @returns SwitchCabinetRecommendationDTO OK
   * @throws ApiError
   */
  public static getAllRecommendations({
    projectId,
    hierarchyId,
    startingAt,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die Hierarchie-ID.
     */
    hierarchyId: number,
    startingAt?: 'COUPLING_RELAY' | 'POWER_ADAPTER' | 'FEED' | 'OVERVOLTAGE' | 'FSM' | 'KA' | 'NETWORK' | 'MISCELLANEOUS' | 'CASINGS_MISC' | 'CASINGS' | 'INSTALLATION_POSITIONS' | 'DDC' | 'OTHER' | 'SERVICE',
  }): CancelablePromise<Array<SwitchCabinetRecommendationDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/cabinets/all',
      query: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
        'startingAt': startingAt,
      },
    });
  }

}
