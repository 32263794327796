/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RequiredLvbDTO = {
  name: string;
  lvb: RequiredLvbDTO.lvb;
  quantity: number;
  requiredQuantity: number;
};

export namespace RequiredLvbDTO {

  export enum lvb {
    NEVER = 'NEVER',
    NONE = 'NONE',
    TMU = 'TMU',
    BMD = 'BMD',
    COUPLING_RELAY = 'COUPLING_RELAY',
  }


}

