import {
  getKvsToDnMappings,
  generateValveShopParameters,
  generateParametersUsingCircuits, generateKvsValues, getPostVariables
} from '@/api/valve'
import {
  GetPostParametersRequest,
  KvsToRangeMappingDTO,
  ValveCircuitRequest,
  ValveCircuitResponse,
  ValveItemShopRequest,
  ValveKvsRequest, ValveKvsResponse, ValveLayoutParametersResponse, ValvePostLayoutParameters,
  ValveShopParametersResponse
} from '@/__generated__'
import { defineStore } from 'pinia'

interface AspState {
  kvsToDnMappings: Array<KvsToRangeMappingDTO> | undefined,
  shopParams: ValveShopParametersResponse | undefined,
  circuitParams: ValveCircuitResponse | undefined,
  kvsValues: ValveKvsResponse | undefined,
  postParams: ValveLayoutParametersResponse | undefined
}

const initialState: AspState = {
  kvsToDnMappings: undefined,
  shopParams: undefined,
  circuitParams: undefined,
  kvsValues: undefined,
  postParams: undefined
}

const useValveStore = defineStore('valve', {
  state: () => {
    return initialState
  },
  actions: {
    async fetchKvsMappings () {
      this.kvsToDnMappings = await getKvsToDnMappings()
    },
    async generateLayoutParameters ({
      params
    }: {
      params: GetPostParametersRequest,
    }) {
      this.postParams = await getPostVariables({
        requestBody: params
      })
    },
    async generateShopParameters ({ request } : { request: ValveItemShopRequest}) {
      this.shopParams = await generateValveShopParameters({ requestBody: request })
      return this.shopParams
    },
    async generateLayoutParametersUsingCircuit ({ requestBody }: {requestBody: ValveCircuitRequest}) {
      this.circuitParams = await generateParametersUsingCircuits({ requestBody })
      return this.circuitParams
    },
    async generateKvsValues ({ requestBody }: {requestBody: ValveKvsRequest}) {
      this.kvsValues = await generateKvsValues({ requestBody })
      return this.kvsValues
    }
  }
})
export { useValveStore }
