/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ItemMetaDTO } from '../models/ItemMetaDTO';
import type { PageNavItem } from '../models/PageNavItem';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NavItemService {

  /**
   * Navision Artikel suchen
   * Sucht Artikel in Navision basierend auf verschiedenen Kriterien wie Kategorie, Seite, Seitenanzahl, Abfrage, Filter und Verkäufer-IDs.
   * @returns PageNavItem OK
   * @throws ApiError
   */
  public static findInNavController({
    category,
    page,
    pageCount = 10,
    query,
    filters,
    vendorIds,
  }: {
    /**
     * Optional. Die Kategorie, nach der gesucht werden soll.
     */
    category?: string,
    /**
     * Die Seite der Ergebnisse, beginnend bei 0.
     */
    page?: number,
    /**
     * Die Anzahl der Ergebnisse pro Seite.
     */
    pageCount?: number,
    /**
     * Optional. Die Abfragezeichenkette für die Suche. Kann bis zu 5 durch Leerzeichen getrennte Suchparameter enthalten. Alle Suchparameter werden gematched.
     */
    query?: string,
    /**
     * Optional. Eine Liste von Filtern, die auf die Suche angewendet werden sollen.
     */
    filters?: Array<string>,
    /**
     * Optional. Eine Menge von Verkäufer-IDs, um die Suche darauf einzuschränken.
     */
    vendorIds?: Array<string>,
  }): CancelablePromise<PageNavItem> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/nav/item',
      query: {
        'category': category,
        'page': page,
        'pageCount': pageCount,
        'query': query,
        'filters': filters,
        'vendorIds': vendorIds,
      },
    });
  }

  /**
   * Metadaten für Navision Artikel abrufen
   * Ruft Metadaten für einen bestimmten Navision Artikel ab, basierend darauf, ob es sich um eine Ressource handelt oder nicht.
   * @returns ItemMetaDTO OK
   * @throws ApiError
   */
  public static getMetaForItem({
    id,
    isResource,
  }: {
    /**
     * Die ID des Artikels oder der Ressource.
     */
    id: string,
    /**
     * Ein Boolean-Wert, der angibt, ob die ID zu einer Ressource / Dienstleistung gehört.
     */
    isResource: boolean,
  }): CancelablePromise<ItemMetaDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/nav/item/meta',
      query: {
        'id': id,
        'isResource': isResource,
      },
    });
  }

}
