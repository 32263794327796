import AuthenticationContext from 'adal-angular'
import { APPLICATION_ID, TENANT_ID } from '../utils/env'

const config: AuthenticationContext.Options = {
  tenant: TENANT_ID,
  clientId: APPLICATION_ID,
  cacheLocation: 'localStorage',
  expireOffsetSeconds: 320,
  redirectUri: window.location.origin
}

type Authentication = {
  authenticationContext: AuthenticationContext;
  initialize: () => Promise<void>;
  acquireToken: (resourcePrincipal: string) => Promise<string>;
  acquireTokenRedirect: (resourcePrincipal: string) => void;
  isAuthenticated: () => boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getUserProfile: () => any;
  signIn: () => void;
  signOut: () => void;
}

const authentication: Authentication = {
  authenticationContext: new AuthenticationContext(config),
  initialize () {
    return new Promise((resolve) => {
      if (this.authenticationContext.isCallback(window.location.hash) || window.self !== window.top) {
        // redirect to the location specified in the url params.
        this.authenticationContext.handleWindowCallback()
      } else {
        // try pull the user out of local storage
        const user = this.authenticationContext.getCachedUser()
        if (user) {
          resolve()
        } else {
          // no user at all - go sign in.
          this.signIn()
        }
      }
    })
  },
  /**
   * @return {Promise.<String>} A promise that resolves to an ADAL token for resource access
   */
  acquireToken (resourcePrincipal: string) {
    return new Promise((resolve, reject) => {
      this.authenticationContext.acquireToken(resourcePrincipal, (error, token) => {
        if (error || !token) {
          return reject(error)
        } else {
          return resolve(token)
        }
      })
    })
  },
  /**
   * Issue an interactive authentication request for the current user and the api resource.
   */
  acquireTokenRedirect (resourcePrincipal: string) {
    this.authenticationContext.acquireTokenRedirect(resourcePrincipal)
  },
  /**
   * @return {Boolean} Indicates if there is a valid, non-expired access token present in localStorage.
   */
  isAuthenticated () {
    // getCachedToken will only return a valid, non-expired token.
    if (this.authenticationContext.getCachedToken(config.clientId)) { return true }
    return false
  },
  /**
   * @return An ADAL user profile object.
   */
  getUserProfile () {
    return this.authenticationContext.getCachedUser().profile
  },
  signIn () {
    this.authenticationContext.login()
  },
  signOut () {
    this.authenticationContext.logOut()
  }
}

export default authentication
