import { ItemQuantity, ProjectConstructionService } from '@/__generated__'
// Project constructions
const getProjectConstructions = (hierarchyId: number, projectId: number) => ProjectConstructionService.getProjectConstructions({ hierarchyId, projectId })
const deleteProjectConstructions = (constructionIds: number[], hierarchyId: number) => ProjectConstructionService.deleteProjectConstructions({ requestBody: constructionIds, hierarchyId })
const copyProjectConstructions = (constructionIds: number[], projectId: number) => ProjectConstructionService.copyProjectConstructions({ requestBody: constructionIds, projectId })
const addProjectConstructions = (plantId: number, projectId: number, constructionId: number | undefined, quantity = 1) => ProjectConstructionService.addProjectConstructions({ plantId, projectId, constructionId, quantity })
const addProjectStrip = (plantId: number, projectId: number, stripId: number, fromMacroLibrary = true) => ProjectConstructionService.addProjectStrip({ plantId, projectId, stripId, fromMacroLibrary })
const changeItems1 = (body: ItemQuantity[], constructionId: number, projectId: number) => ProjectConstructionService.changeItems({ requestBody: body, constructionId, projectId })
const updateProjectConstruction = (constructionId: number, body : { description?: string, selectedSpecification?: number, cableConnection?: string, localOverrideOperation?: string }, projectId: number) => ProjectConstructionService.updateProjectConstruction({ requestBody: body, projectConstructionId: constructionId, projectId })
const replaceProjectStrip = (projectId: number, replacedStripId: number, newStripId: number) => ProjectConstructionService.replaceProjectStrip({ projectId, replacedStripId, stripId: newStripId })
export { changeItems1, addProjectConstructions, getProjectConstructions, deleteProjectConstructions, copyProjectConstructions, addProjectStrip, updateProjectConstruction, replaceProjectStrip }
