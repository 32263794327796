/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NavFilterDTO } from '../models/NavFilterDTO';
import type { NavItemCategoryDTO } from '../models/NavItemCategoryDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NavItemCategoryService {

  /**
   * Kategorien abrufen
   * Ermöglicht das Abrufen von Kategorien aus dem Navision Artikelstamm. Es können entweder spezifische Kategorien oder alle Unterkategorien abgerufen werden, abhängig von den übergebenen Parametern.
   * @returns NavItemCategoryDTO OK
   * @throws ApiError
   */
  public static getCategories({
    parentId,
    showAllSubcategories = false,
    filter,
  }: {
    /**
     * Optional. Die ID der übergeordneten Kategorie.
     */
    parentId?: string,
    /**
     * Wenn true, werden alle Unterkategorien der angegebenen übergeordneten Kategorie zurückgegeben.
     */
    showAllSubcategories?: boolean,
    /**
     * Optional. Ein Filterkriterium zur Einschränkung der zurückgegebenen Kategorien.
     */
    filter?: string,
  }): CancelablePromise<Array<NavItemCategoryDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/nav/category',
      query: {
        'parentId': parentId,
        'showAllSubcategories': showAllSubcategories,
        'filter': filter,
      },
    });
  }

  /**
   * Filter für eine Kategorie abrufen
   * Holt alle verfügbaren Filter für eine bestimmte Kategorie im Navision Artikelstamm.
   * @returns NavFilterDTO OK
   * @throws ApiError
   */
  public static getFiltersForCategory({
    itemCategoryId,
  }: {
    /**
     * Die ID der Kategorie, für die Filter abgerufen werden sollen.
     */
    itemCategoryId: string,
  }): CancelablePromise<Array<NavFilterDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/nav/category/filters',
      query: {
        'itemCategoryId': itemCategoryId,
      },
    });
  }

}
