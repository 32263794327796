/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ItemDTO } from './ItemDTO';

export type SpecificationDetailDTO = {
  id?: number;
  minimalValue?: number;
  maximalValue?: number;
  stringValue?: string;
  constructionId?: number;
  default: boolean;
  items: Array<ItemDTO>;
  itemQuantity: Record<string, number>;
  specificationType: SpecificationDetailDTO.specificationType;
  refId?: number;
};

export namespace SpecificationDetailDTO {

  export enum specificationType {
    NUMERIC = 'NUMERIC',
    DECIMAL = 'DECIMAL',
    RANGE = 'RANGE',
    TEXT = 'TEXT',
    VARIANT = 'VARIANT',
  }


}

