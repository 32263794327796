import { defineStore } from 'pinia'
import {
  MyUser,
  Project,
  ProjectDTO,
  ProjectListItemDTO,
  ProjectPatchRequest,
  ProjectPostRequest
} from '@/__generated__'
import {
  getProject,
  changeProject,
  addProject,
  projects,
  getProjectsUsingQuery,
  deleteProject,
  duplicateProject,
  getCollaborators, addCollaborator, deleteCollaborator, getDescription, changeDescription
} from '@/api'

enum ProjectState {
    NOT_STARTED = 'NOT_STARTED',
    IN_PROGRESS = 'IN_PROGRESS',
    FINISHED = 'FINISHED',
    CANCELLED = 'CANCELLED',
    SENT_COST_ESTIMATION = 'SENT_COST_ESTIMATION',
    SENT_OFFER = 'SENT_OFFER',
    ASSIGNMENT_ACCEPTED = 'ASSIGNMENT_ACCEPTED',
    ASSIGNMENT_DENIED = 'ASSIGNMENT_DENIED'
}

interface ProjectListModel {
    id: number | undefined,
    name: string,
    customer: string,
    location: string,
    userName: string | undefined,
    createdAt: number,
    state: ProjectListItemDTO.state,
    canEdit: boolean,
    isFavorite: boolean,
}

interface ProjectsState {
    projects: Array<ProjectListItemDTO> | undefined,
    users: Array<MyUser> | undefined,
    projectDetailed: ProjectDTO | undefined,
    searchResults: Array<ProjectDTO> | undefined,
    searching: boolean,
    collaborators: MyUser[],
    description: string | undefined
}

const initialState: ProjectsState = {
  projects: undefined,
  users: undefined,
  projectDetailed: undefined,
  searchResults: undefined,
  searching: false,
  collaborators: [],
  description: undefined
}

const useProjectsStore = defineStore('projects', {
  state: () => {
    return initialState
  },
  getters: {
    canEdit (state) : boolean {
      return state.projectDetailed?.canEdit || false
    },
    getUserProjectList (state) : Array<ProjectListModel> {
      return state.projects?.map((i: ProjectListItemDTO) => {
        const user = state.users?.find((u) => u.id === i.user)
        return {
          id: i.id,
          name: i.name,
          customer: i.customer,
          location: i.location,
          userName: user ? `${user.firstName} ${user.lastName}` : undefined,
          createdAt: parseInt(i.createdAt),
          state: i.state,
          canEdit: true,
          isFavorite: i.isFavorite
        }
      }
      ) || []
    },
    getProjectOwnerName (state) : string | undefined {
      return state.projectDetailed?.myUser !== undefined ? `${state.projectDetailed.myUser.firstName} ${state.projectDetailed.myUser.lastName}` : undefined
    },
    getSearchResults (state) : Array<ProjectListModel> {
      return state.searchResults?.map((e) => {
        return {
          id: e.id,
          name: e.name,
          userName: `${e.myUser.lastName}, ${e.myUser.firstName}`,
          // TODO: Find method thad does this using millis
          createdAt: parseInt(e.createdAt.toString()),
          customer: e.customer,
          location: e.location,
          state: e.state,
          canEdit: e.canEdit,
          isFavorite: e.isFavorite
        }
      }) || []
    }
  },
  actions: {
    async fetchProjectList () {
      const data = await projects()
      this.projects = data.projects
      this.users = data.myUsers
    },
    async getCollaborators ({ id } : { id: number}) {
      this.collaborators = await getCollaborators(id)
      this.collaborators.sort((a, b) => a.firstName.localeCompare(b.firstName))
    },
    async fetchDetailedProject (id: number) {
      this.projectDetailed = undefined
      this.projectDetailed = await getProject(id)
      return this.projectDetailed
    },
    async changeProjectProperties (projectId: number, request: ProjectPatchRequest) {
      this.projectDetailed = await changeProject(projectId, request)
    },
    async addProject (request: ProjectPostRequest) {
      const p = await addProject(request)
      this.projects?.push(p)
      return p
    },
    async searchForProjects (query: string) {
      this.searching = true
      this.searchResults = undefined
      this.searchResults = await getProjectsUsingQuery(query)
      this.searching = false
    },
    async deleteProject ({ projectId }: {projectId: number }) {
      await deleteProject({ projectId })
      // Clear out of cache
      this.projectDetailed = undefined
    },
    async duplicateProject ({ projectId, newName }: { projectId: number, newName: string}) {
      //
      this.projectDetailed = await duplicateProject({ projectId, newName })
      return this.projectDetailed
    },
    async addCollaborator ({
      projectId,
      collaboratorId
    }: {
      projectId: number,
      collaboratorId: number,
    }) {
      const newCollaborator = await addCollaborator({ projectId, collaboratorId })
      // Add collaborator, if not existent.
      if (this.collaborators.findIndex((i) => i.id === newCollaborator.id) === -1) {
        this.collaborators = [...this.collaborators, newCollaborator]
      }
      return newCollaborator
    },
    async deleteCollaborator ({
      projectId,
      collaboratorId
    }: {
      projectId: number,
      collaboratorId: number,
    }) {
      await deleteCollaborator({ projectId, collaboratorId })
      this.collaborators = this.collaborators.filter((i) => i.id !== collaboratorId)
    },
    async getDescription ({
      projectId
    }: {
      projectId: number
    }) {
      const response = await getDescription({ projectId })
      this.description = response.description
    },
    async changeDescription ({
      projectId,
      description
    }: {
      projectId: number,
      description: string | undefined
    }) {
      const body = { description }
      this.description = (await changeDescription({ projectId, body: body })).description
    }
  }
})

export { useProjectsStore, Project, ProjectListModel, ProjectState }
