/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetPostParametersRequest } from '../models/GetPostParametersRequest';
import type { KvsToRangeMappingDTO } from '../models/KvsToRangeMappingDTO';
import type { ValveCircuitRequest } from '../models/ValveCircuitRequest';
import type { ValveCircuitResponse } from '../models/ValveCircuitResponse';
import type { ValveItemShopRequest } from '../models/ValveItemShopRequest';
import type { ValveKvsRequest } from '../models/ValveKvsRequest';
import type { ValveKvsResponse } from '../models/ValveKvsResponse';
import type { ValveLayoutParametersResponse } from '../models/ValveLayoutParametersResponse';
import type { ValveShopParametersResponse } from '../models/ValveShopParametersResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ValveService {

  /**
   * Ventilparameter für den Shop generieren
   * Generiert Ventilparameter für den Shop basierend auf spezifischen Anforderungsdaten.
   * @returns ValveShopParametersResponse OK
   * @throws ApiError
   */
  public static generateValveShopParameters({
    requestBody,
    body,
  }: {
    requestBody: ValveItemShopRequest,
    /**
     * Die Anforderungsdaten für die Shop-Parameter.
     */
    body?: any,
  }): CancelablePromise<ValveShopParametersResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/valves/get-shop-parameters',
      query: {
        'body': body,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Ventilauslegungsparameter für den letzten Schritt abrufen
   * Generiert Ventilauslegungsparameter, die für den dritten Schritt benötigt werden. (Schritt 3 Ventilauslegung)
   * @returns ValveLayoutParametersResponse OK
   * @throws ApiError
   */
  public static getPostVariables({
    requestBody,
    body,
  }: {
    requestBody: GetPostParametersRequest,
    /**
     * Die Anforderungsdaten für die Generierung der Auslegungsparameter.
     */
    body?: any,
  }): CancelablePromise<ValveLayoutParametersResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/valves/get-post-variables',
      query: {
        'body': body,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Kvs-Werte generieren
   * Generiert Kvs-Werte für Ventile basierend auf spezifischen Anforderungsdaten. (Schritt 2 Ventilauslegung)
   * @returns ValveKvsResponse OK
   * @throws ApiError
   */
  public static generateKvsValues({
    requestBody,
    body,
  }: {
    requestBody: ValveKvsRequest,
    /**
     * Die Anforderungsdaten für die Generierung der Kvs-Werte.
     */
    body?: any,
  }): CancelablePromise<ValveKvsResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/valves/generate-kvs-value',
      query: {
        'body': body,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Ventilparameter anhand von Schaltungsart berechnen
   * Generiert Ventilparameter basierend auf Schaltungsart, RBQ und Gewerk (Schritt 1 Ventilauslegung).
   * @returns ValveCircuitResponse OK
   * @throws ApiError
   */
  public static generateParametersUsingCircuits({
    requestBody,
    body,
  }: {
    requestBody: ValveCircuitRequest,
    /**
     * Die Anforderungsdaten für die Berechnung der Ventilparameter.
     */
    body?: any,
  }): CancelablePromise<ValveCircuitResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/valves/calculate-variables-using-circuits',
      query: {
        'body': body,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Kvs-zu-DN-Zuordnungen abrufen
   * Holt eine Liste von Kvs-zu-DN-Zuordnungen für Ventile. Im Moment ist dies eine einfache, statische Liste, siehe Service.
   * @returns KvsToRangeMappingDTO OK
   * @throws ApiError
   */
  public static getKvsToDnMappings(): CancelablePromise<Array<KvsToRangeMappingDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/valves/kvs-to-dn-mappings',
    });
  }

}
