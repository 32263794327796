/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ItemQuantity } from './ItemQuantity';

export type ConnectItemsToHierarchyRequest = {
  hierarchyId: number;
  itemList: Array<ItemQuantity>;
  source: ConnectItemsToHierarchyRequest.source;
};

export namespace ConnectItemsToHierarchyRequest {

  export enum source {
    COUPLING_RELAY = 'COUPLING_RELAY',
    POWER_ADAPTER = 'POWER_ADAPTER',
    FEED = 'FEED',
    OVERVOLTAGE = 'OVERVOLTAGE',
    FSM = 'FSM',
    KA = 'KA',
    NETWORK = 'NETWORK',
    MISCELLANEOUS = 'MISCELLANEOUS',
    CASINGS_MISC = 'CASINGS_MISC',
    CASINGS = 'CASINGS',
    INSTALLATION_POSITIONS = 'INSTALLATION_POSITIONS',
    DDC = 'DDC',
    OTHER = 'OTHER',
    SERVICE = 'SERVICE',
  }


}

