/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ProjectPatchRequest = {
  state?: ProjectPatchRequest.state;
  group?: ProjectPatchRequest.group;
  feedbackContact?: ProjectPatchRequest.feedbackContact;
  clipType?: ProjectPatchRequest.clipType;
  discount?: number;
  surcharge?: number;
  visibleLayers?: number;
  exportBaumannByAsp?: boolean;
  name?: string;
  location?: string;
  customer?: string;
  isFavorite?: boolean;
  lvb?: ProjectPatchRequest.lvb;
  deleteLvb?: boolean;
};

export namespace ProjectPatchRequest {

  export enum state {
    NOT_STARTED = 'NOT_STARTED',
    IN_PROGRESS = 'IN_PROGRESS',
    FINISHED = 'FINISHED',
    CANCELLED = 'CANCELLED',
    SENT_COST_ESTIMATION = 'SENT_COST_ESTIMATION',
    SENT_OFFER = 'SENT_OFFER',
    ASSIGNMENT_ACCEPTED = 'ASSIGNMENT_ACCEPTED',
    ASSIGNMENT_DENIED = 'ASSIGNMENT_DENIED',
  }

  export enum group {
    UNDEFINED = 'UNDEFINED',
    OTHER = 'OTHER',
    TRANSPORT = 'TRANSPORT',
    LOGISTICS = 'LOGISTICS',
    SCHOOL = 'SCHOOL',
    RETAIL = 'RETAIL',
    EVENT = 'EVENT',
    OFFICE = 'OFFICE',
    HEALTH = 'HEALTH',
  }

  export enum feedbackContact {
    WITH = 'WITH',
    WITHOUT = 'WITHOUT',
  }

  export enum clipType {
    SCREW = 'SCREW',
    SPRING = 'SPRING',
  }

  export enum lvb {
    NEVER = 'NEVER',
    NONE = 'NONE',
    TMU = 'TMU',
    BMD = 'BMD',
    COUPLING_RELAY = 'COUPLING_RELAY',
  }


}

