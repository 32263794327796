/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProjectPlantDTO } from '../models/ProjectPlantDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProjectPlantService {

  /**
   * Spezifische Anlage eines Projekts abrufen
   * Holt eine spezifische Anlage, die einem Projekt zugeordnet ist, basierend auf der Anlagen-ID.
   * @returns ProjectPlantDTO OK
   * @throws ApiError
   */
  public static getProjectPlant({
    projectId,
    plantId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die ID der Anlage.
     */
    plantId: number,
  }): CancelablePromise<ProjectPlantDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/projects/{projectId}/plants/{plantId}',
      path: {
        'projectId': projectId,
        'plantId': plantId,
      },
    });
  }

  /**
   * Projektanlage aktualisieren
   * Aktualisiert eine spezifische Anlage, die einem Projekt zugeordnet ist. Hier sollte noch Typisierung eingeführt werden.
   * @returns ProjectPlantDTO OK
   * @throws ApiError
   */
  public static updateProjectPlant({
    projectId,
    plantId,
    requestBody,
    fields,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die ID der Anlage.
     */
    plantId: number,
    requestBody: Record<string, any>,
    /**
     * Die zu aktualisierenden Daten der Anlage als Key-Value-Pairs.
     */
    fields?: any,
  }): CancelablePromise<ProjectPlantDTO> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/projects/{projectId}/plants/{plantId}',
      path: {
        'projectId': projectId,
        'plantId': plantId,
      },
      query: {
        'fields': fields,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Anlagen eines Projekts abrufen
   * Holt alle Anlagen, die einem spezifischen Projekt zugeordnet sind, optional gefiltert durch eine Hierarchie-ID.
   * @returns ProjectPlantDTO OK
   * @throws ApiError
   */
  public static getProjectPlants({
    projectId,
    hierarchyId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Optional. Die Hierarchie-ID, um die Ergebnisse zu filtern.
     */
    hierarchyId?: number,
  }): CancelablePromise<Array<ProjectPlantDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/projects/{projectId}/plants',
      path: {
        'projectId': projectId,
      },
      query: {
        'hierarchyId': hierarchyId,
      },
    });
  }

  /**
   * Überprüfen, ob eine Anlage modifiziert wurde
   * Prüft, ob eine bestimmte Anlage seit ihrer Erstellung oder ihrem letzten Update verändert wurde.
   * @returns boolean OK
   * @throws ApiError
   */
  public static getIsPlantModified({
    plantId,
  }: {
    /**
     * Die ID der Anlage.
     */
    plantId: number,
  }): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/projects/plants/is-plant-modified',
      query: {
        'plantId': plantId,
      },
    });
  }

}
