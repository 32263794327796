/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MassLayoutPreviewResponse } from '../models/MassLayoutPreviewResponse';
import type { MassLayoutRequest } from '../models/MassLayoutRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MassLayoutService {

  /**
   * Vorschau für Massenoperation (LVB) anzeigen
   * Vorschau für die Massenänderung von LVB anzeigen. Gibt Anzahl der zu ändernden Anlagen und Komponenten zurück und was wie geändert wird
   * @returns MassLayoutPreviewResponse OK
   * @throws ApiError
   */
  public static getMassOperationsPreview({
    requestBody,
  }: {
    requestBody: MassLayoutRequest,
  }): CancelablePromise<MassLayoutPreviewResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/mass-operations',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Massenoperation (LVB) anwenden
   * Massenänderung für LVB anwenden. Gibt Anzahl der zu ändernden Anlagen und Komponenten zurück und was wie geändert wurde
   * @returns MassLayoutPreviewResponse OK
   * @throws ApiError
   */
  public static applyMassOperations({
    requestBody,
  }: {
    requestBody: MassLayoutRequest,
  }): CancelablePromise<MassLayoutPreviewResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/mass-operations/apply',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
