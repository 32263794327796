/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PriceSumDTO } from '../models/PriceSumDTO';
import type { Response } from '../models/Response';
import type { RoleSecondsPriceDTO } from '../models/RoleSecondsPriceDTO';
import type { ServiceCalculation } from '../models/ServiceCalculation';
import type { ServiceExpensesDatapointListDTO } from '../models/ServiceExpensesDatapointListDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ServiceExpensesCalculatorService {

  /**
   * Dienstleistungsauslegungsberechnung speichern
   * Speichert die Dienstleistungsauslegungsberechnung für ein spezifisches Projekt.
   * @returns Response OK
   * @throws ApiError
   */
  public static persistCalculation({
    projectId,
    hierarchyId,
    method,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die Hierarchie-ID.
     */
    hierarchyId: number,
    /**
     * Die Methode der Dienstleistungsauslegung (Datenpunkte oder Referenzzeitenkalkulation).
     */
    method: 'DATAPOINTS' | 'REFERENCE_TIME',
  }): CancelablePromise<Response> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/services/persist-calculation',
      query: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
        'method': method,
      },
    });
  }

  /**
   * Rollenzeiten und Preise abrufen
   * Holt Rollenzeiten inklusive Preisen für ein spezifisches Projekt. Damit kann ein Preis und Aufwand berechnet werden, feingranular aufgeteilt auf die Rollen
   * @returns RoleSecondsPriceDTO OK
   * @throws ApiError
   */
  public static getRoleTimes({
    projectId,
    hierarchyId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die Hierarchie-ID.
     */
    hierarchyId: number,
  }): CancelablePromise<Array<RoleSecondsPriceDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/services/reference-time-role-prices',
      query: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
      },
    });
  }

  /**
   * Datenpunkte für ein Projekt abrufen
   * Holt Datenpunkte für die Dienstleistungsauslegung eines spezifischen Projekts, gruppiert in physikalisch sowie gemeinsam
   * @returns ServiceExpensesDatapointListDTO OK
   * @throws ApiError
   */
  public static getDatapointsForProject({
    projectId,
    hierarchyId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die Hierarchie-ID.
     */
    hierarchyId: number,
  }): CancelablePromise<ServiceExpensesDatapointListDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/services/datapoints',
      query: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
      },
    });
  }

  /**
   * Geschätzten Preis für die Datenpunkte in einem Hierarchieknoten abrufen
   * Schätzt den Preis für die zusammengefassten Datenpunkte in einem spezifischen Hierarchieknoten.
   * @returns PriceSumDTO OK
   * @throws ApiError
   */
  public static getEstimatedDatapointPrice({
    projectId,
    hierarchyId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die Hierarchie-ID.
     */
    hierarchyId: number,
  }): CancelablePromise<PriceSumDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/services/datapoint-price',
      query: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
      },
    });
  }

  /**
   * Aktuelle Dienstleistungen für einen Hierarchieknoten abrufen
   * Holt die aktuellen Dienstleistungsberechnungen für einen spezifischen Hierarchieknoten.
   * @returns ServiceCalculation OK
   * @throws ApiError
   */
  public static getCurrentServices({
    projectId,
    hierarchyId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die Hierarchie-ID.
     */
    hierarchyId: number,
  }): CancelablePromise<Array<ServiceCalculation>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/services/',
      query: {
        'projectId': projectId,
        'hierarchyId': hierarchyId,
      },
    });
  }

}
