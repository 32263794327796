import {
  HierarchyItemRelation,
  ProjectHierarchyService,
  ProjectHierarchyRequest,
  ItemService,
  DdcService
} from '@/__generated__'
const getItemsConnectedToProjectHierarchy = (
  hierarchyId: number,
  projectId: number,
  source?: 'CASINGS' | 'CASINGS_MISC' | 'COUPLING_RELAY' | 'DDC' | 'FEED' | 'FSM' | 'INSTALLATION_POSITIONS' | 'KA' | 'MISCELLANEOUS' | 'NETWORK' | 'OTHER' | 'OVERVOLTAGE' | 'POWER_ADAPTER'
) => ProjectHierarchyService.getItemsConnectedToProjectHierarchy({ hierarchyId, projectId, source })
const getProjectHierarchy = (projectId: number) => ProjectHierarchyService.getProjectHierarchy({ projectId })
const addProjectHierarchyElement = (projectId: number, request: ProjectHierarchyRequest) => ProjectHierarchyService.addProjectHierarchyElement({ projectId, requestBody: request })
const getHierarchyStatistics = (projectId: number) => ProjectHierarchyService.getHierarchyStatistics({ projectId })
const getProjectHierarchyElement = (hierarchyId: number, projectId: number) => ProjectHierarchyService.getProjectHierarchyElement({ hierarchyId, projectId })
const duplicateProjectHierarchy = (hierarchyId: number, projectId: number) => ProjectHierarchyService.duplicateProjectHierarchy({ hierarchyId, projectId })
const deleteProjectHierarchyElement = (hierarchyId: number, projectId: number) => ProjectHierarchyService.deleteProjectHierarchyElement({ hierarchyId, projectId })
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const editProjectHierarchy = (fields: any, hierarchyId: number, projectId: number) => ProjectHierarchyService.editProjectHierarchy({ requestBody: fields, hierarchyId, projectId })
const deleteItemHierarchyRelation = (hierarchyId: number, itemId: number, projectId: number, source: HierarchyItemRelation.source) => ProjectHierarchyService.deleteItemHierarchyRelation({ hierarchyId, itemId, projectId, source })
const duplicateHierarchyElementIntoParent = (
  {
    sourceProjectId,
    sourceHierarchyId,
    destinationProjectId,
    destinationHierarchyParentId
  }: {
    sourceProjectId: number,
    sourceHierarchyId: number,
    destinationProjectId: number,
    destinationHierarchyParentId?: number,
  }
) => ProjectHierarchyService.duplicateHierarchyElementIntoParent(
  {
    sourceProjectId,
    sourceHierarchyId,
    destinationProjectId,
    destinationHierarchyParentId
  }
)
const getPlantItemsForHierarchyElement = ({
  projectId,
  hierarchyId
}: {
  projectId: number,
  hierarchyId: number,
}) => ItemService.getPlantItemsForHierarchyElement({ projectId, hierarchyId })
const calculateDdcDatapointsUsingProjectId = (projectId: number) => DdcService.calculateDdcDatapointsUsingProjectId({ projectId })
export { deleteItemHierarchyRelation, editProjectHierarchy, deleteProjectHierarchyElement, duplicateProjectHierarchy, addProjectHierarchyElement, getItemsConnectedToProjectHierarchy, getProjectHierarchy, getHierarchyStatistics, getProjectHierarchyElement, duplicateHierarchyElementIntoParent, getPlantItemsForHierarchyElement, calculateDdcDatapointsUsingProjectId }
