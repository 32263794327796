/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ServiceDataPostRequest = {
  itemId: number;
  type: ServiceDataPostRequest.type;
  role: string;
  seconds: number;
  shared: boolean;
};

export namespace ServiceDataPostRequest {

  export enum type {
    BINARY_OUTPUT = 'BINARY_OUTPUT',
    ANALOG_OUTPUT = 'ANALOG_OUTPUT',
    BINARY_INPUT = 'BINARY_INPUT',
    ANALOG_INPUT = 'ANALOG_INPUT',
    COUNTER_INPUT = 'COUNTER_INPUT',
  }


}

