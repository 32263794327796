/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ItemRequest = {
  name: string;
  description?: string;
  type: ItemRequest.type;
};

export namespace ItemRequest {

  export enum type {
    FIELD_DEVICE = 'FIELD_DEVICE',
    SWITCH_CABINET = 'SWITCH_CABINET',
    CABLE = 'CABLE',
    DDC = 'DDC',
    GLT = 'GLT',
    SERVICE = 'SERVICE',
    NOT_DEFINED = 'NOT_DEFINED',
  }


}

