/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Setting = {
  id?: number;
  settingName: string;
  settingValue: string;
  settingType: Setting.settingType;
};

export namespace Setting {

  export enum settingType {
    INT = 'INT',
    FLOAT = 'FLOAT',
    STRING = 'STRING',
    LONG = 'LONG',
  }


}

