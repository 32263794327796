/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConstructionDTO } from '../models/ConstructionDTO';
import type { ItemQuantity } from '../models/ItemQuantity';
import type { NamedFunctionList } from '../models/NamedFunctionList';
import type { ProjectStrip } from '../models/ProjectStrip';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProjectConstructionService {

  /**
   * Projektgruppe ersetzen
   * Ersetzt einen bestehende Gruppe in einem Projekt.
   * @returns ConstructionDTO OK
   * @throws ApiError
   */
  public static replaceProjectStrip({
    projectId,
    replacedStripId,
    stripId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die ID des zu ersetzenden Streifens.
     */
    replacedStripId: number,
    /**
     * Die ID des neuen Streifens.
     */
    stripId: number,
  }): CancelablePromise<Array<ConstructionDTO>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/projects/{projectId}/strips/{replacedStripId}',
      path: {
        'projectId': projectId,
        'replacedStripId': replacedStripId,
      },
      query: {
        'stripId': stripId,
      },
    });
  }

  /**
   * Artikel in einer Komponente ändern
   * Ändert die Artikel einer spezifischen Komponente innerhalb eines Projekts.
   * @returns ConstructionDTO OK
   * @throws ApiError
   */
  public static changeItems({
    projectId,
    constructionId,
    requestBody,
    body,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die ID der Komponente.
     */
    constructionId: number,
    requestBody: Array<ItemQuantity>,
    /**
     * Die Liste von Artikeln mit ihren Mengen, die aktualisiert werden sollen.
     */
    body?: any,
  }): CancelablePromise<ConstructionDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/projects/{projectId}/constructions/{constructionId}/items',
      path: {
        'projectId': projectId,
        'constructionId': constructionId,
      },
      query: {
        'body': body,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Benutzerdefinierten Artikel zu einer Komponente hinzufügen
   * Fügt einen benutzerdefinierten Artikel zu einer spezifischen Komponente innerhalb eines Projekts hinzu.
   * @returns any OK
   * @throws ApiError
   */
  public static addCustomItemToConstruction({
    projectId,
    constructionId,
    name,
    quantity,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die ID der Komponente.
     */
    constructionId: number,
    /**
     * Der Name des hinzuzufügenden Artikels.
     */
    name: string,
    /**
     * Die Anzahl des hinzuzufügenden Artikels.
     */
    quantity: number,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/projects/{projectId}/constructions/{constructionId}/items',
      path: {
        'projectId': projectId,
        'constructionId': constructionId,
      },
      query: {
        'name': name,
        'quantity': quantity,
      },
    });
  }

  /**
   * Status der Funktionsliste ändern
   * Ändert den Status der Funktionsliste für eine spezifische Komponente in einem Projekt.
   * @returns NamedFunctionList OK
   * @throws ApiError
   */
  public static changeFunctionListStatus({
    projectId,
    constructionId,
    isCustom,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die ID der Komponente.
     */
    constructionId: number,
    /**
     * Gibt an, ob es sich um eine benutzerdefinierte Funktionsliste handelt.
     */
    isCustom: boolean,
  }): CancelablePromise<NamedFunctionList> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/projects/{projectId}/constructions/{constructionId}/function-list',
      path: {
        'projectId': projectId,
        'constructionId': constructionId,
      },
      query: {
        'isCustom': isCustom,
      },
    });
  }

  /**
   * Benutzerdefinierte Funktionsliste aktualisieren
   * Aktualisiert die benutzerdefinierte Funktionsliste für eine spezifische Komponente in einem Projekt.
   * @returns NamedFunctionList OK
   * @throws ApiError
   */
  public static updateCustomFunctionList({
    projectId,
    constructionId,
    requestBody,
    fields,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die ID der Konstruktion.
     */
    constructionId: number,
    requestBody: Record<string, any>,
    /**
     * Die zu aktualisierenden Felder der Funktionsliste.
     */
    fields?: any,
  }): CancelablePromise<NamedFunctionList> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/projects/{projectId}/constructions/{constructionId}/function-list',
      path: {
        'projectId': projectId,
        'constructionId': constructionId,
      },
      query: {
        'fields': fields,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Gruppe hinzufügen
   * Fügt einen neuen Gruppe aus der Makrobibliothek zu einem Projekt hinzu.
   * @returns ConstructionDTO OK
   * @throws ApiError
   */
  public static addProjectStrip({
    projectId,
    plantId,
    stripId,
    fromMacroLibrary = true,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die Anlagen-ID, zu der der Streifen hinzugefügt wird.
     */
    plantId: number,
    /**
     * Die ID des hinzuzufügenden Streifens.
     */
    stripId: number,
    /**
     * Gibt an, ob der Streifen aus der Makrobibliothek kopiert wird.
     */
    fromMacroLibrary?: boolean,
  }): CancelablePromise<Array<ConstructionDTO>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/projects/{projectId}/strips',
      path: {
        'projectId': projectId,
      },
      query: {
        'plantId': plantId,
        'stripId': stripId,
        'fromMacroLibrary': fromMacroLibrary,
      },
    });
  }

  /**
   * Projektkomponenten abrufen
   * Holt Komponenten für ein bestimmtes Projekt, optional gefiltert durch eine Hierarchie-ID.
   * @returns ConstructionDTO OK
   * @throws ApiError
   */
  public static getProjectConstructions({
    projectId,
    hierarchyId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Optional. Die Hierarchie-ID, um die Ergebnisse zu filtern.
     */
    hierarchyId?: number,
  }): CancelablePromise<Array<ConstructionDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/projects/{projectId}/constructions',
      path: {
        'projectId': projectId,
      },
      query: {
        'hierarchyId': hierarchyId,
      },
    });
  }

  /**
   * Projektkomponenten hinzufügen
   * Fügt Komponenten zu einem Projekt hinzu. Kopiert eine bestehende Komponente oder fügt eine leere Komponente hinzu, abhängig von der Verfügbarkeit einer Komponenten-ID.
   * @returns ConstructionDTO OK
   * @throws ApiError
   */
  public static addProjectConstructions({
    projectId,
    plantId,
    constructionId,
    quantity = 1,
  }: {
    /**
     * Die Projekt-ID, zu der Komponenten hinzugefügt werden sollen.
     */
    projectId: number,
    /**
     * Die Anlagen-ID, zu der die Komponenten hinzugefügt werden.
     */
    plantId: number,
    /**
     * Die ID der zu kopierenden Komponente. Optional, wenn eine leere Komponente hinzugefügt wird.
     */
    constructionId?: number,
    /**
     * Die Anzahl der hinzuzufügenden Komponenten. Standardmäßig 1, falls nicht angegeben.
     */
    quantity?: number,
  }): CancelablePromise<Array<ConstructionDTO>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/projects/{projectId}/constructions',
      path: {
        'projectId': projectId,
      },
      query: {
        'plantId': plantId,
        'constructionId': constructionId,
        'quantity': quantity,
      },
    });
  }

  /**
   * Projektkomponente aktualisieren
   * Aktualisiert eine spezifische Komponente innerhalb eines Projekts.
   * @returns ConstructionDTO OK
   * @throws ApiError
   */
  public static updateProjectConstruction({
    projectId,
    projectConstructionId,
    requestBody,
    body,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die ID der zu aktualisierenden Komponente.
     */
    projectConstructionId: number,
    requestBody: Record<string, any>,
    /**
     * Die zu aktualisierenden Daten als Key-Value-Paare.
     */
    body?: any,
  }): CancelablePromise<ConstructionDTO> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/projects/{projectId}/constructions',
      path: {
        'projectId': projectId,
      },
      query: {
        'projectConstructionId': projectConstructionId,
        'body': body,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Komponente in einem Projekt duplizieren
   * Dupliziert eine spezifische Komponente innerhalb eines Projekts.
   * @returns ConstructionDTO OK
   * @throws ApiError
   */
  public static copyProjectConstruction({
    projectId,
    constructionId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die ID der zu duplizierenden Komponente.
     */
    constructionId: number,
  }): CancelablePromise<ConstructionDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/projects/{projectId}/constructions/duplicate',
      path: {
        'projectId': projectId,
      },
      query: {
        'constructionId': constructionId,
      },
    });
  }

  /**
   * Mehrere Komponenten in einem Projekt duplizieren
   * Dupliziert mehrere Komponenten innerhalb eines Projekts basierend auf einer Liste von Komponenten-IDs.
   * @returns ConstructionDTO OK
   * @throws ApiError
   */
  public static copyProjectConstructions({
    projectId,
    requestBody,
    constructionIds,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    requestBody: Array<number>,
    /**
     * Die Liste der IDs der zu duplizierenden Komponenten.
     */
    constructionIds?: any,
  }): CancelablePromise<Array<ConstructionDTO>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/projects/{projectId}/constructions/duplicate-multiple',
      path: {
        'projectId': projectId,
      },
      query: {
        'constructionIds': constructionIds,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Gruppe in einem Projekt aktualisieren
   * Aktualisiert eine spezifische Gruppe in einem Projekt.
   * @returns ProjectStrip OK
   * @throws ApiError
   */
  public static updateStrip({
    projectId,
    stripId,
    requestBody,
    body,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die ID der Gruppe.
     */
    stripId: number,
    requestBody: Record<string, any>,
    /**
     * Die zu aktualisierenden Daten als Key-Value-Paare.
     */
    body?: any,
  }): CancelablePromise<ProjectStrip> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/projects/{projectId}/strips/{stripId}',
      path: {
        'projectId': projectId,
        'stripId': stripId,
      },
      query: {
        'body': body,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Gruppe aus einem Projekt löschen
   * Löscht eine spezifische Gruppe aus einem Projekt.
   * @returns any OK
   * @throws ApiError
   */
  public static deleteProjectStrip({
    projectId,
    id,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die ID der zu löschenden Gruppe.
     */
    id: number,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/projects/{projectId}/strips/{id}',
      path: {
        'projectId': projectId,
        'id': id,
      },
    });
  }

  /**
   * @deprecated
   * Komponente aus einem Projekt löschen
   * Löscht eine spezifische Komponente aus einem Projekt. Diese Methode ist veraltet.
   * @returns any OK
   * @throws ApiError
   */
  public static deleteProjectConstruction({
    projectId,
    constructionId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die ID der zu löschenden Komponente.
     */
    constructionId: number,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/projects/{projectId}/constructions/{constructionId}',
      path: {
        'projectId': projectId,
        'constructionId': constructionId,
      },
    });
  }

  /**
   * Mehrere Komponenten aus einem Projekt löschen
   * Löscht mehrere Komponenten aus einem Projekt basierend auf einer Liste von Komponenten-IDs.
   * @returns any OK
   * @throws ApiError
   */
  public static deleteProjectConstructions({
    hierarchyId,
    requestBody,
    constructionIds,
  }: {
    /**
     * Die Hierarchie-ID, aus der die Komponenten gelöscht werden.
     */
    hierarchyId: number,
    requestBody: Array<number>,
    /**
     * Die Liste der IDs der zu löschenden Komponenten.
     */
    constructionIds?: any,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/projects/{hierarchyId}/constructions',
      path: {
        'hierarchyId': hierarchyId,
      },
      query: {
        'constructionIds': constructionIds,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
