/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SpecificationRequest = {
  stringValue?: string;
  minimalValue?: number;
  maximalValue?: number;
  constructionId?: number;
  specificationType: SpecificationRequest.specificationType;
  isDefault: boolean;
  refId?: number;
};

export namespace SpecificationRequest {

  export enum specificationType {
    NUMERIC = 'NUMERIC',
    DECIMAL = 'DECIMAL',
    RANGE = 'RANGE',
    TEXT = 'TEXT',
    VARIANT = 'VARIANT',
  }


}

