/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProjectRoleDTO } from '../models/ProjectRoleDTO';
import type { ProjectRolePatchRequest } from '../models/ProjectRolePatchRequest';
import type { ProjectRolePostRequest } from '../models/ProjectRolePostRequest';
import type { ProjectRolePutRequest } from '../models/ProjectRolePutRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProjectRoleService {

  /**
   * Rolle ersetzen
   * Ersetzt eine bestehende Rolle mit neuen Daten.
   * @returns ProjectRoleDTO OK
   * @throws ApiError
   */
  public static replaceRole({
    id,
    requestBody,
    request,
  }: {
    /**
     * Die ID der zu ersetzenden Rolle.
     */
    id: string,
    requestBody: ProjectRolePutRequest,
    /**
     * Die Anforderungsdaten für die neue Rolle.
     */
    request?: any,
  }): CancelablePromise<ProjectRoleDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/roles/{id}',
      path: {
        'id': id,
      },
      query: {
        'request': request,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Rolle löschen
   * Löscht eine spezifische Rolle basierend auf ihrer ID.
   * @returns any OK
   * @throws ApiError
   */
  public static deleteRole({
    id,
  }: {
    /**
     * Die ID der zu löschenden Rolle.
     */
    id: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/roles/{id}',
      path: {
        'id': id,
      },
    });
  }

  /**
   * Rolle aktualisieren
   * Aktualisiert eine spezifische Rolle basierend auf ihrer ID.
   * @returns ProjectRoleDTO OK
   * @throws ApiError
   */
  public static updateRole({
    id,
    requestBody,
    request,
  }: {
    /**
     * Die ID der Rolle.
     */
    id: string,
    requestBody: ProjectRolePatchRequest,
    /**
     * Die Anforderungsdaten für die Aktualisierung der Rolle.
     */
    request?: any,
  }): CancelablePromise<ProjectRoleDTO> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/roles/{id}',
      path: {
        'id': id,
      },
      query: {
        'request': request,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Alle Rollen finden
   * Holt alle vorhandenen Rollen, die für die Dienstauslegung verwendet werden.
   * @returns ProjectRoleDTO OK
   * @throws ApiError
   */
  public static findAllRoles(): CancelablePromise<Array<ProjectRoleDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/roles',
    });
  }

  /**
   * Neue Rolle hinzufügen
   * Fügt eine neue Rolle hinzu.
   * @returns ProjectRoleDTO OK
   * @throws ApiError
   */
  public static addRole({
    requestBody,
    request,
  }: {
    requestBody: ProjectRolePostRequest,
    /**
     * Die Anforderungsdaten für die neue Rolle.
     */
    request?: any,
  }): CancelablePromise<ProjectRoleDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/roles',
      query: {
        'request': request,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
