import { defineStore } from 'pinia'
import { fetchWrapper } from '@/helpers/fetch-wrapper'
import { BACKEND_URI } from '@/utils/env'
import { searchUser } from '@/api/users'
import { CancelablePromise, MyUser } from '@/__generated__'
const baseUrl = `${BACKEND_URI}/users`
let requestForSearchUsers = undefined as CancelablePromise<Array<MyUser>> | undefined
interface UserInfo {
    id: number,
    createdAt: string,
    firstName: string,
    lastName: string,
    isAdmin: boolean,
    mail: string
}

interface UserGraphInfo {
    tags: []
}
interface UsersState {
    isLoggedIn: boolean,
    user?: UserInfo,
    graphInfo?: UserGraphInfo,
    isConnectingToBackend: boolean,
    isConnectedToBackend: boolean,
    searchedUsers: MyUser[]
}

const initialState : UsersState = {
  isLoggedIn: false,
  user: undefined,
  graphInfo: undefined,
  isConnectingToBackend: true,
  isConnectedToBackend: false,
  searchedUsers: []
}

const useUserStore = defineStore('users', {
  state: () => {
    return initialState
  },
  getters: {
    getCurrentUserName: (state) => {
      return state.user ? `${state.user.firstName} ${state.user.lastName}` : undefined
    },
    getCurrentUserIsAdmin: (state) => {
      return state.user?.isAdmin || false
    },
    getCurrentUserInitials: (state) => {
      return state.user ? `${state.user.firstName[0]}${state.user.lastName[0]}` : undefined
    }
  },
  actions: {
    async fetchCurrentUser () {
      try {
        this.user = await fetchWrapper.get(`${baseUrl}/me`) as UserInfo
        this.isConnectedToBackend = true
        this.isLoggedIn = true
        return this.user
      } catch (e) {
        this.isConnectedToBackend = false
        this.isConnectingToBackend = false
        console.error(e)
        throw Error()
      }
    },
    async searchUsers (query: string) {
      await requestForSearchUsers?.cancel()
      requestForSearchUsers = searchUser(query)
      this.searchedUsers = await requestForSearchUsers
    },
    async logout () {
      this.user = undefined
    },
    async clearResults () {
      await requestForSearchUsers?.cancel()
      this.searchedUsers = []
    }
  }
})

export { useUserStore, UserInfo }
