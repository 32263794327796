import { getMetaFor, getCategories, findInNavController, searchForNavResources, getFiltersForCategory } from '@/api'
import { CancelablePromise, ItemMetaDTO, NavFilterDTO, NavItemCategoryDTO, PageNavItem, PageNavResource } from '@/__generated__'
import { defineStore } from 'pinia'
import { FilterOptionStringValue } from '@/model/FilterOptionStringValue'
import { FilterOptionMinMaxValue } from '@/model/FilterOptionMinMaxValue'

let parentCategoryPromise: CancelablePromise<Array<NavItemCategoryDTO>> | undefined

interface MacroLibraryState {
    meta: Array<ItemMetaDTO>,
    categories: Array<NavItemCategoryDTO> | undefined,
    children: Array<NavItemCategoryDTO>,
    searchResults: PageNavItem | undefined,
    navResources: PageNavResource | undefined,
    filters: Array<NavFilterDTO> | undefined
}

const initialState: MacroLibraryState = {
  meta: [],
  categories: undefined,
  children: [],
  navResources: undefined,
  searchResults: undefined,
  filters: undefined
}

type CategoryNode = {
    id: string,
    name: string,
}

const useNavStore = defineStore('nav', {
  state: () => {
    return initialState
  },
  actions: {
    async getMetaForItem (id: string, isResource: boolean) {
      // Filter out old meta data
      const a = this.meta.find((i) => i.id === id)
      if (a !== undefined) {
        return a
      }
      const m = await getMetaFor(id, isResource)
      // Push new metadata
      this.meta.push(m)
      this.meta = [...this.meta]
      return m
    },
    async getBaseCategories () {
      if (parentCategoryPromise === undefined) {
        parentCategoryPromise = getCategories()
      }
      this.categories = await parentCategoryPromise
    },
    async getChildCategories (parentId: string) {
      const c = await getCategories(undefined, parentId)
      const newChildren = c.filter((child) => !this.children.map((e) => e.id).includes(child.id))
      this.children.push(...newChildren)
      this.children = [...this.children]
    },
    async findItems (
      category: string | undefined,
      page: number,
      pageCount: number,
      query: string | undefined,
      stringFilters: FilterOptionStringValue[],
      minMaxFilters: FilterOptionMinMaxValue[],
      vendorIds: string[] | undefined
    ) {
      // serialize filters
      this.searchResults = undefined
      const serializedFilters =
      [
        ...stringFilters.map((e) => `string:${e.id}:${e.itemAttributeId}`),
        ...minMaxFilters.map((e) => `range:${e.id}:${e.minValue}:${e.maxValue}`)
      ]
      this.searchResults = await findInNavController(category, page, pageCount, query, serializedFilters, vendorIds)
    },
    async clearItems () {
      this.searchResults = undefined
    },
    async findResources (query: string | undefined, page: number, pageCount: number) {
      this.navResources = await searchForNavResources(page, pageCount, query)
    },
    async loadFiltersForCategory (itemCategoryId: string) {
      this.filters = []
      this.filters = await getFiltersForCategory({ itemCategoryId })
      this.filters?.sort((a, b) => a.name.localeCompare(b.name))
    },
    removeFilters () {
      this.filters = []
    }
  },
  getters: {
    getSearchResults (state) {
      return state.searchResults?.content || []
    },
    getMetaForId (state) : (id: string) => ItemMetaDTO | undefined {
      return (id: string) => {
        return state.meta.find((i) => i.id === id)
      }
    },
    getCategoriesAsTree (state) : Array<CategoryNode> | undefined {
      return state.categories?.map((i) => {
        return {
          id: i.id,
          name: `${i.id} ${i.description}`,
          children: i.hasChildren
            ? state.children.filter((a) => a.parentId === i.id).map((c) => {
              return {
                id: c.id,
                name: `${c.id} ${c.description}`,
                children: c.hasChildren
                  ? state.children.filter((b) => b.parentId === c.id).map((d) => {
                    return {
                      id: d.id,
                      name: `${d.id} ${d.description}`
                    }
                  }) ?? []
                  : null
              }
            }) ?? []
            : null
        }
      })
    }
  }
})
export { useNavStore, CategoryNode }
