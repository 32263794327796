import { searchVendor } from '@/api/vendor'
import { CancelablePromise, MyUser, PageVendorDTO } from '@/__generated__'
import { defineStore } from 'pinia'

let lastRequestForVendors = undefined as CancelablePromise<PageVendorDTO> | undefined

interface VendorState {
  vendorPage: PageVendorDTO |undefined
}

const initialState: VendorState = {
  vendorPage: undefined
}
const useNavVendorsStore = defineStore('nav-vendors', {
  state: () => {
    return initialState
  },
  actions: {
    async searchVendors ({ page, query }: { page: number, query: string}) {
      lastRequestForVendors?.cancel()
      lastRequestForVendors = searchVendor({ page, query })
      this.vendorPage = await lastRequestForVendors
    },
    clearResults () {
      this.vendorPage = undefined
    }
  }
})
export { useNavVendorsStore }
