/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ItemQuantityWithSourcePriceAndType = {
  name: string;
  quantity: number;
  source: ItemQuantityWithSourcePriceAndType.source;
  addedManually: boolean;
  id: number;
  itemType: ItemQuantityWithSourcePriceAndType.itemType;
  price: number;
};

export namespace ItemQuantityWithSourcePriceAndType {

  export enum source {
    COUPLING_RELAY = 'COUPLING_RELAY',
    POWER_ADAPTER = 'POWER_ADAPTER',
    FEED = 'FEED',
    OVERVOLTAGE = 'OVERVOLTAGE',
    FSM = 'FSM',
    KA = 'KA',
    NETWORK = 'NETWORK',
    MISCELLANEOUS = 'MISCELLANEOUS',
    CASINGS_MISC = 'CASINGS_MISC',
    CASINGS = 'CASINGS',
    INSTALLATION_POSITIONS = 'INSTALLATION_POSITIONS',
    DDC = 'DDC',
    OTHER = 'OTHER',
    SERVICE = 'SERVICE',
  }

  export enum itemType {
    FIELD_DEVICE = 'FIELD_DEVICE',
    SWITCH_CABINET = 'SWITCH_CABINET',
    CABLE = 'CABLE',
    DDC = 'DDC',
    GLT = 'GLT',
    SERVICE = 'SERVICE',
    NOT_DEFINED = 'NOT_DEFINED',
  }


}

