import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { $t } from '@/utils/use-i18n'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: $t('steps.home').toString(),
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/projects/:id',
    name: $t('steps.projectConfiguration').toString(),
    component: () => import('../views/ProjectView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/add-project',
    name: 'Projekt hinzufügen',
    component: () => import('../views/AddProjectView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/search',
    name: $t('steps.searchProjects').toString(),
    component: () => import('../views/SearchView.vue')
  },
  {
    path: '/help',
    name: $t('steps.help').toString(),
    component: () => import('../views/HelpView.vue')
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
