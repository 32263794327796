/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ItemQuantityType } from './ItemQuantityType';

export type ConstructionSimpleDTO = {
  id?: number;
  name?: string;
  unit?: string;
  unitDescription?: string;
  localOverrideOperation: ConstructionSimpleDTO.localOverrideOperation;
  airSided: boolean;
  items: Array<ItemQuantityType>;
  isActive?: boolean;
  hasValve: boolean;
};

export namespace ConstructionSimpleDTO {

  export enum localOverrideOperation {
    NEVER = 'NEVER',
    NONE = 'NONE',
    TMU = 'TMU',
    BMD = 'BMD',
    COUPLING_RELAY = 'COUPLING_RELAY',
  }


}

