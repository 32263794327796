/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PlantDTO } from '../models/PlantDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PublicPlantService {

  /**
   * Alle öffentlichen Anlagen laden
   * Hole alle öffentlichen Anlagen, (Status = 'fertig'), die in der Makrobibliothek verfügbar sind
   * @returns PlantDTO OK
   * @throws ApiError
   */
  public static getAllPublicPlants(): CancelablePromise<Array<PlantDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/macrolib/public/plants',
    });
  }

  /**
   * Bestimmte öffentliche Anlage laden
   * Hole bestimmte alle öffentlichen mittels ID, (Status = 'fertig'), die in der Makrobibliothek verfügbar sind.Wirft Fehler, wenn die Anlage noch nicht released ist.
   * @returns PlantDTO OK
   * @throws ApiError
   */
  public static getPublicPlantById({
    id,
  }: {
    /**
     * ID der Anlage, die abgerufen werden soll.
     */
    id: number,
  }): CancelablePromise<PlantDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/macrolib/public/plants/{id}',
      path: {
        'id': id,
      },
    });
  }

}
