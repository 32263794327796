/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { KvsToRangeMappingDTO } from './KvsToRangeMappingDTO';

export type ValveCircuitResponse = {
  type: ValveCircuitResponse.type;
  pressureDifference: number;
  kvsToRangeMappings: Array<KvsToRangeMappingDTO>;
};

export namespace ValveCircuitResponse {

  export enum type {
    STRAIGHT_THROUGH = 'STRAIGHT_THROUGH',
    THREE_WAY = 'THREE_WAY',
    FOUR_WAY = 'FOUR_WAY',
    SIX_WAY = 'SIX_WAY',
  }


}

