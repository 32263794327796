/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ValveCircuitRequest = {
  circuit: ValveCircuitRequest.circuit;
  groupCombination: ValveCircuitRequest.groupCombination;
  usePower: boolean;
};

export namespace ValveCircuitRequest {

  export enum circuit {
    THROTTLE_CIRCUIT = 'THROTTLE_CIRCUIT',
    MIXING_CIRCUIT = 'MIXING_CIRCUIT',
    INJECTION_CIRCUIT = 'INJECTION_CIRCUIT',
    DEFLECTION_CIRCUIT = 'DEFLECTION_CIRCUIT',
    MIXING_CIRCUIT_WITH_FIXED_PREMIXING = 'MIXING_CIRCUIT_WITH_FIXED_PREMIXING',
    INJECTION_CIRCUIT_WITH_FIXED_PREMIXING = 'INJECTION_CIRCUIT_WITH_FIXED_PREMIXING',
  }

  export enum groupCombination {
    HEATING = 'HEATING',
    COOLING = 'COOLING',
  }


}

