/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MigrationPostRequest } from '../models/MigrationPostRequest';
import type { PlantMigrationDTO } from '../models/PlantMigrationDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PlantMigrationService {

  /**
   * Anlage zu einem Projekt migrieren
   * Kopiert eine Anlage zu einem Projekthierarchieelement unter Verwendung der spezifizierten Anfrageparameter.
   * @returns PlantMigrationDTO OK
   * @throws ApiError
   */
  public static copyPlantToProject({
    requestBody,
    body,
  }: {
    requestBody: MigrationPostRequest,
    /**
     * Der Request-Body mit den notwendigen Informationen zur Durchführung der Migration.
     */
    body?: any,
  }): CancelablePromise<PlantMigrationDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/migration/plant-to-project',
      query: {
        'body': body,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
